/* eslint-disable eqeqeq */
/* eslint-disable import/extensions */
import { useEffect, useMemo, useState } from 'react';
import { withTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { useQueryClient } from 'react-query';
import InstagramIcon from 'assets/iconComponents/InstagramIcon';
import YoutubeIcon from 'assets/iconComponents/YoutubeIcon';
import GlobeIcon from 'assets/iconComponents/Globe';
import ComboBox from 'components/shared/ComboBox/ComboBox';
import { useTheme } from 'styled-components';
import InputControlV2 from 'components/shared/InputControlV2/InputControlV2';
import MagnifyingGlassIcon from 'assets/iconComponents/MagnifyingGlassIcon';

import * as S from './Styles';
import Loader from '../../components/shared/LoaderFixed/LoaderFixed';
import GenericModal from '../../components/shared/GenericModal/GenericModal';
import { MyAnalyticsProps } from './types';
import BackArrowIcon from '../../assets/iconComponents/BackArrowIcon';
import { useGetSelfUser } from '../../Services/Utils/CustomHooks';
import {
  DEFAULT_USER_AVATAR,
  PublicProfile,
  SocialNetworks,
} from '../../Services/Utils/types';
import {
  getSocialNetworkIcon,
  isAuthenticated,
} from '../../Services/Utils/Utils';

const MyAnalytics = (props: MyAnalyticsProps) => {
  const [isGetSelfUserEnabled, setIsGetSelfUserEnabled] = useState(false);
  const [hasError, setHasError] = useState(false);
  const [msgError, setMsgError] = useState('');
  const {
    data: selfUser,
    isSuccess: isSuccessGetSelfUser,
    isFetching: isFetchingGetSelfUser,
    isError: isErrorGetSelfUser,
  } = useGetSelfUser(isGetSelfUserEnabled);
  const [publicProfiles, setPublicProfiles] = useState<PublicProfile[]>([]);
  const [username, setUsername] = useState<string>('');
  const [network, setNetwork] = useState<string>('ALL');
  const themeGlobal = useTheme();
  const socialNetworkAvailableValues = useMemo(() => {
    return [
      {
        value: 'ALL',
        label: (
          <S.SocialNetworkFilterIcon globalEntry={true}>
            <GlobeIcon
              svgWidth="2rem"
              svgHeight="2rem"
              containerMarginRight="1rem"
            />
            {props.t('generic.all')}
          </S.SocialNetworkFilterIcon>
        ),
      },
      {
        value: SocialNetworks.INSTAGRAM,
        label: (
          <S.SocialNetworkFilterIcon>
            <InstagramIcon
              svgWidth="2rem"
              svgHeight="2rem"
              gradientColored={true}
              containerMarginRight="1rem"
            />
            {props.t('socialNetworks.INSTAGRAM')}
          </S.SocialNetworkFilterIcon>
        ),
      },
      {
        value: SocialNetworks.YOUTUBE,
        label: (
          <S.SocialNetworkFilterIcon>
            <YoutubeIcon
              svgWidth="2rem"
              svgHeight="2rem"
              containerMarginRight="1rem"
            />
            {props.t('socialNetworks.YOUTUBE')}
          </S.SocialNetworkFilterIcon>
        ),
      },
    ];
  }, []);
  const navigate = useNavigate();
  const queryClient = useQueryClient();

  useEffect(() => {
    const invalidateGetSelfUserCache = async () => {
      await queryClient.invalidateQueries({
        queryKey: ['get-user', isAuthenticated()?.userId],
      });
    };

    invalidateGetSelfUserCache();
    setIsGetSelfUserEnabled(true);
  }, []);

  useEffect(() => {
    if (isSuccessGetSelfUser) {
      setIsGetSelfUserEnabled(false);

      setPublicProfiles(
        selfUser.publicProfiles?.sort((profileA, profileB) =>
          profileA.username < profileB.username ? -1 : 1,
        ) ?? [],
      );
    }
  }, [isSuccessGetSelfUser]);

  useEffect(() => {
    if (isErrorGetSelfUser) {
      setIsGetSelfUserEnabled(false);
      setHasError(true);
      setMsgError(props.t('generic.couldNotGetData'));
    }
  }, [isErrorGetSelfUser]);

  const getLoaderMessage = () => {
    if (isFetchingGetSelfUser) {
      return props.t('generic.gettingData');
    }
    return '';
  };

  const errorModalCloseButtonClicked = () => {
    setHasError(false);
    if (isErrorGetSelfUser) {
      setIsGetSelfUserEnabled(true);
    }
  };

  return (
    <S.Container>
      <Loader show={isFetchingGetSelfUser} message={getLoaderMessage()} />

      <S.InnerContainer>
        <S.Card>
          <S.HeaderOuterContainer>
            <S.HeaderContainer>
              <S.HeaderBackArrowContainer
                onClick={() => {
                  navigate(-1);
                }}
              >
                <BackArrowIcon width={'4rem'} height={'4rem'} />
              </S.HeaderBackArrowContainer>
              <S.HeaderTitleContainer>
                {props.t('externalMediaKitConfigurationsList.title')}
              </S.HeaderTitleContainer>
              <S.HiddenHeaderElement />
            </S.HeaderContainer>
          </S.HeaderOuterContainer>

          <S.FiltersRow>
            <S.UsernameFilterInputContainer>
              <InputControlV2
                value={username}
                onChange={(inputValue: string) => setUsername(inputValue)}
                inputFieldContainerWidth={'100%'}
                placeholder={props.t('generic.searchByUsernamePlaceholder')}
                inputFieldLineHeight={'2.5rem'}
                inputFieldPaddingTop={'1rem'}
                inputFieldPaddingBottom={'1rem'}
                inputFieldPaddingRight={'5rem'}
              />
              <S.MagnifyingGlassContainer>
                <MagnifyingGlassIcon
                  containerWidth="3rem"
                  containerHeight="3rem"
                />
              </S.MagnifyingGlassContainer>
            </S.UsernameFilterInputContainer>

            <S.SocialNetworkFilterContainer>
              <S.SocialNetworkFilterInnerContainer>
                <ComboBox
                  width={'100%'}
                  type={'single'}
                  height={'4.4rem'}
                  growWithoutScroll
                  value={network}
                  valueKey={'network'}
                  borderRadius={'0.6rem'}
                  valuesAvailable={socialNetworkAvailableValues}
                  isClearable={false}
                  isFilterable={false}
                  onChange={(obj: any) => {
                    setNetwork(obj.network);
                  }}
                  optionContainerBorderRadius={'0.6rem'}
                  availableOptionsContainerBorderRadius={'0.6rem'}
                  innerOptionsContainerPaddingTop={'1.2rem'}
                  innerOptionsContainerPaddingBottom={'1.2rem'}
                  innerOptionsContainerPaddingLeft={'1.2rem'}
                  innerOptionsContainerPaddingRight={'1.2rem'}
                  innerOptionsContainerTextAlign={'start'}
                  optionContainerPaddingTop={'1rem'}
                  optionContainerPaddingBottom={'1rem'}
                  optionContainerPaddingLeft={'1rem'}
                  optionContainerPaddingRight={'1rem'}
                  innerOptionsContainerMaxHeight={'25rem'}
                  optionSelectedContainerPaddingLeft={'0'}
                  changeBackgroundColorOnHover={false}
                  themeStyles={(themeGlobal as any).comboBoxTopicsOfInterest}
                />
              </S.SocialNetworkFilterInnerContainer>
            </S.SocialNetworkFilterContainer>
          </S.FiltersRow>

          <S.CardInnerContainer>
            {selfUser && publicProfiles && (
              <S.SocialAccountsInnerContainer>
                {publicProfiles
                  ?.filter(
                    publicProfile =>
                      publicProfile.username.includes(username) &&
                      (network === 'ALL' ||
                        publicProfile.socialNetwork === network),
                  )
                  .map(publicProfile => (
                    <S.SocialAccountContainer
                      onClick={() => {
                        navigate(
                          `/user-media-kit/${publicProfile.publicUserId}`,
                        );
                      }}
                    >
                      <S.SocialAccountLeftContainer>
                        <S.SocialAccountImage
                          src={
                            publicProfile.displayPicture ?? DEFAULT_USER_AVATAR
                          }
                        />
                        <S.SocialAccountNetworkIconContainer>
                          {getSocialNetworkIcon({
                            socialNetwork: publicProfile.socialNetwork,
                            width: '2rem',
                            height: '2rem',
                            gradientColored: true,
                          })}
                        </S.SocialAccountNetworkIconContainer>
                      </S.SocialAccountLeftContainer>

                      <S.SocialAccountRightContainer>
                        <S.SocialAccountUsername
                        // id={`media-kit-username-${publicProfile.username}-${publicProfile.socialNetwork}`}
                        >
                          {`@${publicProfile.username}`}
                        </S.SocialAccountUsername>
                        <S.SocialAccountNetworkName>
                          {publicProfile.socialNetwork[0] +
                            publicProfile.socialNetwork.slice(1).toLowerCase()}
                        </S.SocialAccountNetworkName>
                        {/* {publicProfile.username.length > 15 && (
                        <S.ConfigurationUsernameTooltip
                          placement="top"
                          target={`media-kit-username-${publicProfile.username}-${publicProfile.socialNetwork}`}
                        >
                          {publicProfile.username}
                        </S.ConfigurationUsernameTooltip>
                      )} */}
                      </S.SocialAccountRightContainer>
                    </S.SocialAccountContainer>
                  ))}
              </S.SocialAccountsInnerContainer>
            )}

            {selfUser?.publicProfiles?.length == 0 && (
              <S.SocialAccountsEmpty>
                <S.SocialAccountsEmptyInner>
                  <div>
                    {props.t('menu-navbar.noSocialAccountsPrimaryMessage')}
                  </div>
                  <S.SocialAccountsEmptySecondaryMessage>
                    <S.SocialAccountsEmptyLink
                      onClick={() => navigate('/social-accounts-management')}
                    >
                      {props.t(
                        'menu-navbar.noSocialAccountsSecondaryMessagePart1',
                      )}
                    </S.SocialAccountsEmptyLink>
                    {props.t(
                      'menu-navbar.noSocialAccountsSecondaryMessagePart2',
                    )}
                  </S.SocialAccountsEmptySecondaryMessage>
                </S.SocialAccountsEmptyInner>
              </S.SocialAccountsEmpty>
            )}
          </S.CardInnerContainer>
        </S.Card>
      </S.InnerContainer>

      <GenericModal
        show={hasError}
        title={props.t('generic.uhoh')}
        body={msgError}
        primaryButtonText={props.t('generic.close')}
        primaryButtonAction={() => errorModalCloseButtonClicked()}
      />
    </S.Container>
  );
};

export default withTranslation()(MyAnalytics);
