/* eslint-disable no-useless-escape */
/* eslint-disable default-case */
/* eslint-disable consistent-return */
/* eslint-disable eqeqeq */
/* eslint-disable import/extensions */
import { useNavigate } from 'react-router-dom';
import { useState, useEffect, useRef, useMemo } from 'react';
import { useTheme } from 'styled-components';
import { withTranslation, Trans } from 'react-i18next';
import { useQueryClient } from 'react-query';
import TiktokIcon from 'assets/iconComponents/TiktokIcon';
import MagnifyingGlassIcon from 'assets/iconComponents/MagnifyingGlassIcon';
import ComboBox from 'components/shared/ComboBox/ComboBox';
import GlobeIcon from 'assets/iconComponents/Globe';

import * as S from './Styles';
import GenericModal from '../../components/shared/GenericModal/GenericModal';
import StyledButton from '../../components/shared/Button/Button';
import { getThemeStyles } from '../../css/ThemeGlobal';
import {
  DEFAULT_USER_AVATAR,
  PartnerTypes,
  PublicProfile,
  SocialNetworks,
} from '../../Services/Utils/types';
import Loader from '../../components/shared/LoaderFixed/LoaderFixed';
import { IInfluencerProfileEditProps } from './types';
import NotifyCustom from '../../components/shared/NotifyCustom/NotifyCustom';
import CheckMarkIcon from '../../assets/iconComponents/CheckMarkIcon';
import BackArrowIcon from '../../assets/iconComponents/BackArrowIcon';
import InputControlV2 from '../../components/shared/InputControlV2/InputControlV2';
import auth from '../../api/auth-helper';
import {
  getSocialNetworkIcon,
  isAuthenticated,
} from '../../Services/Utils/Utils';
import InstagramIcon from '../../assets/iconComponents/InstagramIcon';
import YoutubeIcon from '../../assets/iconComponents/YoutubeIcon';
import { useGetSelfUser } from '../../Services/Utils/CustomHooks';
import {
  useGetPublicUserBasicInformationWithExternalProvider,
  useUpdateUserPublicProfiles,
} from './RQCustomHooks';
import InfoV2Icon from '../../assets/iconComponents/InfoIconV2';

const SocialAccountManagement = (props: IInfluencerProfileEditProps) => {
  const [username, setUsername] = useState<string>('');
  const [socialNetworkSelected, setSocialNetworkSelected] = useState<
    SocialNetworks | undefined
  >();
  const [publicProfiles, setPublicProfiles] = useState<PublicProfile[]>([]);
  const [isGetSelfUserEnabled, setIsGetSelfUserEnabled] =
    useState<boolean>(false);
  const [
    isGetPublicUserBasicInformationEnabled,
    setIsGetPublicUserBasicInformationEnabled,
  ] = useState<boolean>(false);
  const socialAccountUsernameFieldRef = useRef();
  const [usernameFilter, setUsernameFilter] = useState<string>('');
  const [networkFilter, setNetworkFilter] = useState<string>('ALL');
  const socialNetworkAvailableValues = useMemo(() => {
    return [
      {
        value: 'ALL',
        label: (
          <S.SocialNetworkFilterIcon globalEntry={true}>
            <GlobeIcon
              svgWidth="2rem"
              svgHeight="2rem"
              containerMarginRight="1rem"
            />
            {props.t('generic.all')}
          </S.SocialNetworkFilterIcon>
        ),
      },
      {
        value: SocialNetworks.INSTAGRAM,
        label: (
          <S.SocialNetworkFilterIcon>
            <InstagramIcon
              svgWidth="2rem"
              svgHeight="2rem"
              gradientColored={true}
              containerMarginRight="1rem"
            />
            {props.t('socialNetworks.INSTAGRAM')}
          </S.SocialNetworkFilterIcon>
        ),
      },
      {
        value: SocialNetworks.YOUTUBE,
        label: (
          <S.SocialNetworkFilterIcon>
            <YoutubeIcon
              svgWidth="2rem"
              svgHeight="2rem"
              containerMarginRight="1rem"
            />
            {props.t('socialNetworks.YOUTUBE')}
          </S.SocialNetworkFilterIcon>
        ),
      },
    ];
  }, []);
  const [hasError, setHasError] = useState(false);
  const [msgError, setMsgError] = useState('');
  const queryClient = useQueryClient();
  const {
    data: selfUser,
    isFetching: isFetchingGetSelfUser,
    isSuccess: isSuccessGetSelfUser,
    isError: isErrorGetSelfUser,
  } = useGetSelfUser(isGetSelfUserEnabled);
  const {
    data: publicUserBasicInformation,
    isFetching: isFetchingGetPublicUserBasicInformation,
    isSuccess: isSuccessGetPublicUserBasicInformation,
    isError: isErrorGetPublicUserBasicInformation,
  } = useGetPublicUserBasicInformationWithExternalProvider({
    enabled: isGetPublicUserBasicInformationEnabled,
    params: {
      username: username.trim().replace('@', '').toLocaleLowerCase(),
      socialNetwork: socialNetworkSelected,
    },
    setHasError,
    setMsgError,
    t: props.t,
  });
  const {
    isLoading: isLoadingUpdateUserPublicProfiles,
    isSuccess: isSuccessUpdateUserPublicProfiles,
    isError: isErrorUpdateUserPublicProfiles,
    mutate: updateUserPublicProfiles,
  } = useUpdateUserPublicProfiles();
  const [
    showUpdateUserPublicProfilesSuccessNotification,
    setShowUpdateUserPublicProfilesSuccessNotification,
  ] = useState<boolean>(false);
  const [
    showUpdateUserPublicProfilesSuccessNotificationText,
    setShowUpdateUserPublicProfilesSuccessNotificationText,
  ] = useState<boolean>(false);
  const navigate = useNavigate();
  const themeGlobal = useTheme();

  useEffect(() => {
    if (auth.isAuthenticated().partnerType == PartnerTypes.influencer) {
      setIsGetSelfUserEnabled(true);
    }
  }, []);

  useEffect(() => {
    if (isSuccessGetSelfUser) {
      setPublicProfiles(
        selfUser.publicProfiles?.sort((profileA, profileB) =>
          profileA.username < profileB.username ? -1 : 1,
        ) ?? [],
      );
    }
  }, [isSuccessGetSelfUser]);

  useEffect(() => {
    if (
      isSuccessGetPublicUserBasicInformation &&
      isGetPublicUserBasicInformationEnabled &&
      Object.values(publicUserBasicInformation).length
    ) {
      setIsGetPublicUserBasicInformationEnabled(false);

      setUsername('');

      const newAddedSocialAccount = publicUserBasicInformation;
      setPublicProfiles(
        [
          ...publicProfiles,
          {
            ...newAddedSocialAccount,
            publicUserId: newAddedSocialAccount.publicUserId ?? null,
          },
        ].sort((profileA, profileB) =>
          profileA.username < profileB.username ? -1 : 1,
        ),
      );
    }
  }, [
    isSuccessGetPublicUserBasicInformation,
    isGetPublicUserBasicInformationEnabled,
    publicUserBasicInformation,
  ]);

  useEffect(() => {
    if (isSuccessUpdateUserPublicProfiles) {
      setShowUpdateUserPublicProfilesSuccessNotification(true);
      setShowUpdateUserPublicProfilesSuccessNotificationText(true);
      setTimeout(() => {
        setShowUpdateUserPublicProfilesSuccessNotification(false);
      }, 2000);
      setTimeout(() => {
        setShowUpdateUserPublicProfilesSuccessNotificationText(false);
      }, 2600);

      const invalidateGetSelfUserCache = async () => {
        await queryClient.invalidateQueries({
          queryKey: ['get-user', isAuthenticated()?.userId],
        });
      };

      invalidateGetSelfUserCache();
    }
  }, [isSuccessUpdateUserPublicProfiles]);

  useEffect(() => {
    if (isErrorGetSelfUser) {
      setHasError(true);
      setMsgError(
        props.t('socialAccountManagement.error-loading-social-accounts-data'),
      );
      setIsGetSelfUserEnabled(false);
    }
  }, [isErrorGetSelfUser]);

  useEffect(() => {
    if (isErrorGetPublicUserBasicInformation) {
      setIsGetPublicUserBasicInformationEnabled(false);
    }
  }, [isErrorGetPublicUserBasicInformation]);

  useEffect(() => {
    if (isErrorUpdateUserPublicProfiles) {
      setHasError(true);
      setMsgError(
        props.t('socialAccountManagement.error-updating-user-public-profiles'),
      );
    }
  }, [isErrorUpdateUserPublicProfiles]);

  useEffect(() => {
    if (socialNetworkSelected) {
      (socialAccountUsernameFieldRef.current as any).focus();
    }
  }, [socialNetworkSelected]);

  const cancelButtonClicked = () => {
    navigate(-1);
  };

  const getLoaderMessage = () => {
    if (isFetchingGetSelfUser) {
      return props.t('socialAccountManagement.loading-social-accounts-data');
    }
    if (isFetchingGetPublicUserBasicInformation) {
      return props.t(
        'socialAccountManagement.addSocialAccounts.getting-social-account-data',
      );
    }
    if (isLoadingUpdateUserPublicProfiles) {
      return props.t(
        'socialAccountManagement.addSocialAccounts.updating-user-social-accounts',
      );
    }
    return '';
  };

  const getActionResultTooltipMessage = () => {
    if (showUpdateUserPublicProfilesSuccessNotificationText) {
      return props.t(
        'socialAccountManagement.addSocialAccounts.social-accounts-updated-successfully',
      );
    }
  };

  const errorModalCloseButtonClicked = () => {
    if (isErrorGetSelfUser) {
      setIsGetSelfUserEnabled(true);
    }
    setHasError(false);
  };

  const removeAddedSocialAccount = (publicProfile: PublicProfile) => {
    const newPublicProfiles = publicProfiles.filter(
      publicProfileLocal =>
        !(
          publicProfileLocal.username == publicProfile.username &&
          publicProfileLocal.socialNetwork == publicProfile.socialNetwork
        ),
    );

    setPublicProfiles(newPublicProfiles);
  };

  const addButtonClicked = () => {
    setIsGetPublicUserBasicInformationEnabled(true);
  };

  const isUsernameValid = () => {
    const usernameTrimmed = (username || '').trim().toLocaleLowerCase();

    const re = /^(?=.*[a-zA-Z0-9])[a-zA-Z0-9._]+$/;

    return (
      re.test(usernameTrimmed) &&
      usernameTrimmed.length &&
      !usernameTrimmed.includes(' ')
    );
  };

  const isAddButtonDisabled = () => {
    const socialAccountAlreadyExists = publicProfiles.find(
      publicProfile =>
        publicProfile.username == username &&
        publicProfile.socialNetwork == socialNetworkSelected,
    );

    return Boolean(
      socialAccountAlreadyExists ||
        !(username && socialNetworkSelected) ||
        !isUsernameValid(),
    );
  };

  const socialNetworkInputKeyUpHandler = (event: any) => {
    if (event.keyCode == 13 && !isAddButtonDisabled()) {
      addButtonClicked();
    }
  };

  const saveButtonClicked = () => {
    updateUserPublicProfiles({ publicProfiles });
  };

  const connectNetworkButtonClicked = (socialNetwork: SocialNetworks) => {
    switch (socialNetwork) {
      case SocialNetworks.INSTAGRAM: {
        if (socialNetworkSelected != SocialNetworks.INSTAGRAM) {
          setSocialNetworkSelected(SocialNetworks.INSTAGRAM);
        } else {
          setSocialNetworkSelected(undefined);
        }
        break;
      }
      case SocialNetworks.YOUTUBE: {
        if (socialNetworkSelected != SocialNetworks.YOUTUBE) {
          setSocialNetworkSelected(SocialNetworks.YOUTUBE);
        } else {
          setSocialNetworkSelected(undefined);
        }
      }
    }
  };

  const getUsernameInputHelperText = () => {
    switch (socialNetworkSelected) {
      case SocialNetworks.INSTAGRAM: {
        return (
          <Trans i18nKey="socialAccountManagement.addSocialAccounts.usernameInputHelperMessageInstagram" />
        );
      }
      case SocialNetworks.YOUTUBE: {
        return (
          <Trans i18nKey="socialAccountManagement.addSocialAccounts.usernameInputHelperMessageYoutube" />
        );
      }
      default: {
        return '';
      }
    }
  };

  return (
    <S.Container>
      <Loader
        show={
          isFetchingGetSelfUser ||
          isFetchingGetPublicUserBasicInformation ||
          isLoadingUpdateUserPublicProfiles
        }
        message={getLoaderMessage()}
      />

      <S.InnerContainer>
        <S.Card>
          <S.HeaderOuterContainer>
            <S.HeaderContainer>
              <S.HeaderBackArrowContainer
                onClick={() => {
                  navigate(-1);
                }}
              >
                <BackArrowIcon width={'4rem'} height={'4rem'} />
              </S.HeaderBackArrowContainer>
              <S.HeaderTitleContainer>
                {props.t('socialAccountManagement.title')}
              </S.HeaderTitleContainer>
              <S.HiddenHeaderElement />
            </S.HeaderContainer>
          </S.HeaderOuterContainer>

          <S.FiltersRow>
            <S.UsernameFilterInputContainer>
              <InputControlV2
                value={usernameFilter}
                onChange={(inputValue: string) => setUsernameFilter(inputValue)}
                inputFieldContainerWidth={'100%'}
                placeholder={props.t('generic.searchByUsernamePlaceholder')}
                inputFieldLineHeight={'2.5rem'}
                inputFieldPaddingTop={'1rem'}
                inputFieldPaddingBottom={'1rem'}
                inputFieldPaddingRight={'5rem'}
              />
              <S.MagnifyingGlassContainer>
                <MagnifyingGlassIcon
                  containerWidth="3rem"
                  containerHeight="3rem"
                />
              </S.MagnifyingGlassContainer>
            </S.UsernameFilterInputContainer>

            <S.SocialNetworkFilterContainer>
              <S.SocialNetworkFilterInnerContainer>
                <ComboBox
                  width={'100%'}
                  type={'single'}
                  height={'4.4rem'}
                  growWithoutScroll
                  value={networkFilter}
                  valueKey={'network'}
                  borderRadius={'0.6rem'}
                  valuesAvailable={socialNetworkAvailableValues}
                  isClearable={false}
                  isFilterable={false}
                  onChange={(obj: any) => {
                    setNetworkFilter(obj.network);
                  }}
                  optionContainerBorderRadius={'0.6rem'}
                  availableOptionsContainerBorderRadius={'0.6rem'}
                  innerOptionsContainerPaddingTop={'1.2rem'}
                  innerOptionsContainerPaddingBottom={'1.2rem'}
                  innerOptionsContainerPaddingLeft={'1.2rem'}
                  innerOptionsContainerPaddingRight={'1.2rem'}
                  innerOptionsContainerTextAlign={'start'}
                  optionContainerPaddingTop={'1rem'}
                  optionContainerPaddingBottom={'1rem'}
                  optionContainerPaddingLeft={'1rem'}
                  optionContainerPaddingRight={'1rem'}
                  innerOptionsContainerMaxHeight={'25rem'}
                  optionSelectedContainerPaddingLeft={'0'}
                  changeBackgroundColorOnHover={false}
                  themeStyles={(themeGlobal as any).comboBoxTopicsOfInterest}
                />
              </S.SocialNetworkFilterInnerContainer>
            </S.SocialNetworkFilterContainer>
          </S.FiltersRow>

          <S.CardInnerContainer>
            <S.AddedSocialAccountsContainer>
              <S.Title>
                {props.t('socialAccountManagement.addedSocialAccounts.title')}
              </S.Title>

              {publicProfiles?.length == 0 ? (
                <S.AddedSocialAccountsEmpty>
                  {props.t(
                    'socialAccountManagement.addedSocialAccounts.noAddedSocialAccountsMessage',
                  )}
                </S.AddedSocialAccountsEmpty>
              ) : (
                <S.AddedSocialAccountsInnerContainer>
                  {publicProfiles
                    ?.filter(
                      publicProfile =>
                        publicProfile.username.includes(usernameFilter) &&
                        (networkFilter === 'ALL' ||
                          publicProfile.socialNetwork === networkFilter),
                    )
                    ?.map(publicProfile => (
                      <S.AddedSocialAccountContainer>
                        <S.AddedSocialAccountLeftContainer>
                          <S.AddedSocialAccountImage
                            src={
                              publicProfile.displayPicture ??
                              DEFAULT_USER_AVATAR
                            }
                          />
                          <S.AddedSocialAccountNetworkIconContainer>
                            {getSocialNetworkIcon({
                              socialNetwork: publicProfile.socialNetwork,
                              width: '2rem',
                              height: '2rem',
                              gradientColored: true,
                            })}
                          </S.AddedSocialAccountNetworkIconContainer>
                        </S.AddedSocialAccountLeftContainer>

                        <S.AddedSocialAccountRightContainer>
                          <S.AddedSocialAccountUsername>
                            {`@${publicProfile.username}`}
                          </S.AddedSocialAccountUsername>
                          <S.AddedSocialAccountNetworkName>
                            {publicProfile.socialNetwork[0] +
                              publicProfile.socialNetwork
                                .slice(1)
                                .toLowerCase()}
                          </S.AddedSocialAccountNetworkName>
                        </S.AddedSocialAccountRightContainer>

                        <S.AddedSocialAccountDeleteContainer>
                          <S.DeleteButton
                            onClick={() =>
                              removeAddedSocialAccount(publicProfile)
                            }
                          />
                        </S.AddedSocialAccountDeleteContainer>
                      </S.AddedSocialAccountContainer>
                    ))}
                </S.AddedSocialAccountsInnerContainer>
              )}
            </S.AddedSocialAccountsContainer>

            <S.AddSocialAccountContainer
              onKeyUp={event => socialNetworkInputKeyUpHandler(event)}
            >
              <S.Title>
                {props.t('socialAccountManagement.addSocialAccounts.title')}
              </S.Title>

              <S.SubTitle>
                {props.t('socialAccountManagement.addSocialAccounts.subtitle')}
              </S.SubTitle>

              <S.ConnectSocialAccountButtonsContainer>
                <S.ConnectInstagramAccountButton
                  disabled={socialNetworkSelected == SocialNetworks.YOUTUBE}
                  onClick={() =>
                    connectNetworkButtonClicked(SocialNetworks.INSTAGRAM)
                  }
                >
                  <InstagramIcon
                    containerWidth="2rem"
                    containerHeight="2rem"
                    noAssignColor={true}
                  />
                  {props.t(
                    'socialAccountManagement.addSocialAccounts.connect-instagram-account-button',
                  )}
                  <S.HiddenElement className="hidden-element" />
                </S.ConnectInstagramAccountButton>

                <S.ConnectYoutubeAccountButton
                  disabled={socialNetworkSelected == SocialNetworks.INSTAGRAM}
                  onClick={() =>
                    connectNetworkButtonClicked(SocialNetworks.YOUTUBE)
                  }
                >
                  <YoutubeIcon
                    containerWidth="2rem"
                    containerHeight="2rem"
                    noAssignColor={true}
                  />
                  {props.t(
                    'socialAccountManagement.addSocialAccounts.connect-youtube-account-button',
                  )}
                  <S.HiddenElement className="hidden-element" />
                </S.ConnectYoutubeAccountButton>

                <S.ConnectTiktokAccountButton disabled={true}>
                  <TiktokIcon
                    containerWidth="2rem"
                    containerHeight="2rem"
                    disabledColored={true}
                  />
                  {props.t(
                    'socialAccountManagement.addSocialAccounts.connect-tiktok-account-button',
                  )}
                  <S.HiddenElement className="hidden-element" />
                  <S.TiktokSoonContainer>{'soon'}</S.TiktokSoonContainer>
                </S.ConnectTiktokAccountButton>
              </S.ConnectSocialAccountButtonsContainer>

              <S.AddSocialAccountsInnerContainer
                visible={socialNetworkSelected != undefined}
              >
                <S.UsernameInputContainer>
                  <InputControlV2
                    value={username}
                    onChange={(inputValue: string) => setUsername(inputValue)}
                    refNotNative={socialAccountUsernameFieldRef}
                    title={props.t(
                      'socialAccountManagement.addSocialAccounts.usernameInputTitle',
                    )}
                    inputFieldContainerWithTitleWidth={'100%'}
                    placeholder={props.t(
                      'socialAccountManagement.addSocialAccounts.usernameInputPlaceholder',
                    )}
                    inputTitleMarginBottom={'1.2rem'}
                    inputFieldContainerMarginBottom={'1.4rem'}
                  />
                  <S.UsernameInputInformationContainer>
                    <S.UsernameInputInfoIconContainer>
                      <InfoV2Icon
                        svgWidth="2.7rem"
                        svgHeight="2.7rem"
                        containerMarginLeft="1rem"
                        containerMarginRight="1rem"
                        noAssignColor={true}
                      />
                    </S.UsernameInputInfoIconContainer>
                    <S.UsernameInputInformationInnerContainer>
                      {getUsernameInputHelperText()}
                    </S.UsernameInputInformationInnerContainer>
                  </S.UsernameInputInformationContainer>
                </S.UsernameInputContainer>

                <S.AddSocialAccountButtonContainer>
                  <StyledButton
                    borderRadius={'0.6rem'}
                    borderWidth={'0rem'}
                    height={'4rem'}
                    fontSize={'1.6rem'}
                    textAlign={'center'}
                    fontWeight={'500'}
                    themeStyles={
                      (themeGlobal as any).createExternalMediaKitConfiguration
                        .createButton
                    }
                    disabled={isAddButtonDisabled()}
                    onClick={() => addButtonClicked()}
                  >
                    {props.t('generic.add')}
                  </StyledButton>
                </S.AddSocialAccountButtonContainer>
              </S.AddSocialAccountsInnerContainer>
            </S.AddSocialAccountContainer>
          </S.CardInnerContainer>

          <S.Footer>
            <S.SaveButtonContainer>
              <StyledButton
                borderRadius={'0.6rem'}
                borderWidth={'0rem'}
                height={'4rem'}
                fontSize={'1.6rem'}
                textAlign={'center'}
                fontWeight={'500'}
                paddingRight={'1rem'}
                marginLeft={'auto'}
                marginRight={'1.5rem'}
                onClick={() => cancelButtonClicked()}
                themeStyles={getThemeStyles(
                  ['button', 'secondary-1'],
                  themeGlobal,
                )}
              >
                {props.t('generic.cancel')}
              </StyledButton>
            </S.SaveButtonContainer>

            <S.SaveButtonContainer
              id={'influencer-profile-save-button-disabled'}
            >
              <StyledButton
                borderRadius={'0.6rem'}
                borderWidth={'0rem'}
                height={'4rem'}
                fontSize={'1.6rem'}
                textAlign={'center'}
                fontWeight={'500'}
                themeStyles={
                  (themeGlobal as any).createExternalMediaKitConfiguration
                    .createButton
                }
                onClick={() => saveButtonClicked()}
              >
                {props.t('generic.save')}
              </StyledButton>
            </S.SaveButtonContainer>
          </S.Footer>
        </S.Card>
      </S.InnerContainer>

      <GenericModal
        show={hasError}
        title={props.t('generic.uhoh')}
        body={msgError}
        primaryButtonText={props.t('generic.close')}
        primaryButtonAction={() => {
          errorModalCloseButtonClicked();
        }}
      />

      <NotifyCustom
        location={'top'}
        show={showUpdateUserPublicProfilesSuccessNotification}
        themeStyles={(themeGlobal as any).signin['success-notify']}
        topInitialOffset={'5rem'}
      >
        <S.NotifyInnerContainer>
          <CheckMarkIcon
            containerWidth={'2rem'}
            containerHeight={'2rem'}
            containerMarginRight={'1rem'}
            useCase={'success-notify'}
          />
          {getActionResultTooltipMessage()}
        </S.NotifyInnerContainer>
      </NotifyCustom>
    </S.Container>
  );
};

export default withTranslation()(SocialAccountManagement);
