/* eslint-disable consistent-return */
/* eslint-disable import/no-cycle */
import styled, { css } from 'styled-components';

import {
  IAddSocialAccountsInnerContainerProps,
  IConnectInstagramAccountButtonProps,
  ITitleProps,
  SocialNetworkFilterIconProps,
} from './types';

export const Container = styled.div`
  width: 100%;
  height: auto;
  display: flex;
  justify-content: center;
  align-items: start;
  padding-left: 0.8rem;
`;

export const InnerContainer = styled.div`
  width: 75%;
  border-radius: 2.4rem;
  height: auto;
  background-color: ${props =>
    props.theme['additional-data-step']['background-color']};
  box-shadow: ${props =>
    `0rem 1.6rem 2.4rem ${props.theme['additional-data-step']['box-shadow-color-1']}, 0rem 0.6rem 3rem ${props.theme['additional-data-step']['box-shadow-color-2']}, 0rem 0.8rem 1rem ${props.theme['additional-data-step']['box-shadow-color-3']}`};
  margin: 2rem 0;

  @media (max-width: 1180px) {
    width: 85%;
  }

  @media (max-width: 960px) {
    width: 90%;
  }

  @media (max-width: 600px) {
    width: 100%;
  }
`;

export const HeaderOuterContainer = styled.div`
  height: 7.7rem;
  margin-bottom: 3rem;
  border-bottom: 0.1rem solid
    ${props => props.theme.signin['signin-content-divider-color']};
`;

export const HeaderContainer = styled.div`
  display: flex;
  justify-content: space-around;
  align-items: center;
  height: 100%;
  padding: 0 3.2rem;
  font-size: 2rem;
  font-weight: 600;
  color: ${props => props.theme.text['color-26']};
`;

export const HeaderBackArrowContainer = styled.div`
  height: 4.2rem;
  width: 4.2rem;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0.8rem;
  transition: all 0.08s;
  opacity: 1;
  border-radius: 0.8rem;
  cursor: pointer;
  & path {
    fill: ${props =>
      props.theme.signin['signin-forgot-password-back-arrow-color']};
  }

  &:hover {
    background-color: ${props =>
      props.theme.signin[
        'signin-forgot-password-back-arrow-container-background-color-hover'
      ]};
  }
`;

export const HeaderTitleContainer = styled.div`
  font-size: 2rem;
  font-weight: 600;
  color: ${props => props.theme.text['text-26']};
  margin: 0 auto;
`;

export const HiddenHeaderElement = styled.div`
  height: 4rem;
  width: 4rem;
  visibility: hidden;
`;

export const FiltersRow = styled.div`
  display: flex;
  justify-content: space-between;
  padding-left: 3.2rem;
  padding-right: 3.2rem;
  margin-bottom: 2rem;

  @media (max-width: 925px) {
    flex-direction: column;
  }
`;

export const UsernameFilterInputContainer = styled.div`
  width: 55rem;
  position: relative;

  &:hover input {
    border-color: rgb(41, 41, 41);
  }

  @media (max-width: 925px) {
    width: 100%;
    margin-bottom: 1rem;
  }
`;

export const MagnifyingGlassContainer = styled.div`
  position: absolute;
  right: 1rem;
  top: 50%;
  transform: translateY(-50%);
`;

export const SocialNetworkFilterContainer = styled.div`
  display: flex;
  width: 20rem;

  @media (max-width: 925px) {
    width: 100%;
    justify-content: center;
  }
`;

export const SocialNetworkFilterInnerContainer = styled.div`
  width: 100%;

  @media (max-width: 925px) {
    width: 20rem;
  }

  @media (max-width: 500px) {
    width: 100%;
  }
`;

export const SocialNetworkFilterIcon = styled.div<SocialNetworkFilterIconProps>`
  display: flex;
  align-items: center;
  color: #292929;

  ${props => {
    if (props.globalEntry) {
      return css`
        & path {
          fill: #8494a9;
        }
      `;
    }
  }}
`;

export const CardInnerContainer = styled.div`
  padding: 0 3.2rem;
`;

export const TitleContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 2.4rem;
`;

export const Title = styled.div<ITitleProps>`
  color: ${props => props.theme.text['color-26']};
  font-size: 1.8rem;
  font-weight: 600;
  margin-top: ${props => (props.marginTop ? props.marginTop : '0')};
  margin-bottom: ${props => (props.marginBottom ? props.marginBottom : '0')};
  text-align: left;
`;

export const SubTitle = styled.div<ITitleProps>`
  color: #676767;
  font-size: 1.4rem;
  font-weight: 400;
  margin-top: ${props => (props.marginTop ? props.marginTop : '0.9rem')};
  margin-bottom: ${props => (props.marginBottom ? props.marginBottom : '0')};
  text-align: left;
`;

export const ConnectSocialAccountButtonsContainer = styled.div`
  display: flex;
  justify-content: space-evenly;
  padding: 0rem;
  margin-top: 1.5rem;

  @media (max-width: 1500px) {
    flex-direction: column;
    align-items: center;
  }
`;

export const ConnectInstagramAccountButton = styled.div<IConnectInstagramAccountButtonProps>`
  display: flex;
  justify-content: space-between;
  align-items: center;
  background: ${props =>
    props.disabled
      ? '#F4F4F4'
      : 'linear-gradient(90deg, #FFEA00 -29.55%, #FF00E1 128.36%)'};
  background-blend-mode: multiply;
  height: 5.2rem;
  width: 31rem;
  border-radius: 1rem;
  color: ${props => (props.disabled ? 'rgb(191, 191, 191)' : '#FFFFFF')};
  font-size: 1.6rem;
  font-weight: 700;
  padding: 1.8rem 1.55rem;
  cursor: pointer;
  user-select: none;

  svg path {
    fill: ${props => (props.disabled ? 'rgb(191, 191, 191)' : '#FFFFFF')};
  }

  &:hover {
    background: linear-gradient(90deg, #fff059 -29.55%, #ff47f2 128.36%);
    color: #ffffff;

    svg path {
      fill: #ffffff;
    }
  }

  & > .hidden-element {
    width: 2rem;
    height: 2rem;
  }

  @media (max-width: 1500px) {
    margin-bottom: 1rem;
  }
`;

export const ConnectYoutubeAccountButton = styled.div<IConnectInstagramAccountButtonProps>`
  display: flex;
  justify-content: space-between;
  align-items: center;
  background-color: ${props => (props.disabled ? '#F4F4F4' : '#FF0000')};
  height: 5.2rem;
  width: 31rem;
  border-radius: 1rem;
  color: ${props => (props.disabled ? 'rgb(191, 191, 191)' : '#FFFFFF')};
  font-size: 1.6rem;
  font-weight: 700;
  padding: 1.8rem 1.55rem;
  cursor: pointer;
  user-select: none;

  svg path {
    fill: ${props => (props.disabled ? 'rgb(191, 191, 191)' : '#FFFFFF')};
  }

  &:hover {
    background-color: #ff6d6d;
    color: #ffffff;

    svg path {
      fill: #ffffff;
    }
  }

  & > .hidden-element {
    width: 2rem;
    height: 2rem;
  }

  @media (max-width: 1500px) {
    margin-bottom: 1rem;
  }
`;

export const ConnectTiktokAccountButton = styled.div<IConnectInstagramAccountButtonProps>`
  display: flex;
  justify-content: space-between;
  align-items: center;
  background-color: ${props => (props.disabled ? '#F4F4F4' : '#0C0C0C')};
  height: 5.2rem;
  width: 31rem;
  max-width: 31rem;
  border-radius: 1rem;
  color: ${props => (props.disabled ? 'rgb(191, 191, 191)' : '#FFFFFF')};
  font-size: 1.6rem;
  font-weight: 700;
  padding: 1.8rem 1.55rem;
  user-select: none;
  position: relative;

  & > .hidden-element {
    width: 2rem;
    height: 2rem;
  }
`;

export const Card = styled.div`
  height: 100%;
  display: flex;
  flex-direction: column;
`;

export const AddedSocialAccountsContainer = styled.div`
  display: flex;
  flex-direction: column;
  margin-bottom: 4rem;
`;

export const AddedSocialAccountsEmpty = styled.div`
  color: ${props => props.theme.text['color-26']};
  font-size: 1.6rem;
  font-weight: 400;
  padding-top: 2rem;
  padding-left: 2rem;
  margin-bottom: 1rem;
`;

export const AddedSocialAccountsInnerContainer = styled.div`
  margin-top: 2rem;
  display: flex;
  flex-wrap: wrap;
  max-height: 16rem;
  overflow-y: overlay;

  @media (max-width: 750px) {
    justify-content: center;
  }
`;

export const AddedSocialAccountContainer = styled.div`
  height: 7rem;
  width: 23rem;
  display: flex;
  margin-right: 2rem;
  border: 1px solid rgb(238, 238, 238);
  border-radius: 1.5rem;
  padding: 0.5rem;
  margin-bottom: 1rem;

  @media (max-width: 750px) {
    width: 80%;
  }

  @media (max-width: 600px) {
    width: 100%;
  }
`;

export const AddedSocialAccountLeftContainer = styled.div`
  height: 6rem;
  width: 6rem;
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
`;

export const AddedSocialAccountImage = styled.img`
  height: 100%;
  width: 100%;
  border-radius: 50%;
`;

export const AddedSocialAccountNetworkIconContainer = styled.div`
  padding: 0.2rem;
  display: inline-flex;
  justify-content: center;
  align-items: center;
  border-radius: 0.5rem;
  position: absolute;
  top: 60%;
  left: 60%;
  background-color: white;
`;

export const AddedSocialAccountRightContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  flex-grow: 1;
  padding-left: 0.8rem;
`;

export const AddedSocialAccountUsername = styled.div`
  padding-bottom: 0.9rem;
  color: ${props => props.theme.text['color-26']};
  font-size: 1.5rem;
  font-weight: 600;
  width: 13rem;
  overflow-x: hidden;
  text-overflow: ellipsis;
`;

export const AddedSocialAccountNetworkName = styled.div`
  font-size: 1.4rem;
  font-weight: 400;
  line-height: 1rem;
  color: ${props => props.theme.text['color-27']};
  position: relative;
  display: inline-block;
  margin-top: 0.9rem;
`;

export const AddedSocialAccountDeleteContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 2rem;
  margin-left: 0.4rem;
`;

export const DeleteButton = styled.div`
  height: 1.8rem;
  width: 1.8rem;
  border: 0.2rem solid #bababa;
  border-radius: 50%;
  cursor: pointer;
  position: relative;
  transform: rotate(45deg);

  &:hover,
  &:hover::before,
  &:hover::after {
    border-color: #878787;
  }

  &::before {
    content: '';
    display: block;
    width: 60%;
    height: 0;
    border: 0.1rem solid #bababa;
    border-radius: 0.3rem;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translateX(-50%) translateY(-50%);
  }

  &::after {
    content: '';
    display: block;
    height: 60%;
    width: 0;
    border: 0.1rem solid #bababa;
    border-radius: 0.3rem;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translateX(-50%) translateY(-50%);
  }
`;

export const BPDetailsContainer = styled.div`
  display: flex;
  flex-direction: column;
  padding-bottom: 3rem;
  margin-bottom: 3rem;
  border-bottom: 0.1rem solid ${props => props.theme.text['color-18']};
`;

export const AddSocialAccountsInnerContainer = styled.div<IAddSocialAccountsInnerContainerProps>`
  display: flex;
  margin-top: ${props => (props.visible ? '3rem' : '0')};
  height: ${props => (props.visible ? '14rem' : 0)};
  overflow: hidden;
  transition: all 0.2s ease-out;

  @media (max-width: 880px) {
    margin-top: ${props => (props.visible ? '2rem' : '1rem')};
  }
`;

export const UsernameInputContainer = styled.div`
  width: 50%;
  display: flex;
  flex-direction: column;

  @media (max-width: 1000px) {
    width: 70%;
  }

  @media (max-width: 620px) {
    width: 80%;
  }
`;

export const UsernameInputInformationContainer = styled.div`
  display: flex;
  color: #676767;
  font-size: 1.4rem;
  font-weight: 400;

  svg path {
    fill: #bfbfbf;
  }
`;

export const UsernameInputInfoIconContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: start;
`;

export const UsernameInputInformationInnerContainer = styled.div`
  word-break: break-word;
`;

export const SocialNetworkPickerTitleContainer = styled.div`
  font-size: 1.4rem;
  font-weight: 400;
  line-height: 1rem;
  margin-bottom: 1.2rem;
  color: ${props => props.theme.text['color-27']};
  position: relative;
  display: inline-block;
`;

export const Footer = styled.div`
  display: flex;
  justify-content: end;
  align-items: end;
  margin-top: 3rem;
  flex-grow: 1;
  padding: 1.5rem 3.2rem;
  border-top: 0.1rem solid
    ${props => props.theme.signin['signin-content-divider-color']};

  @media (max-width: 1000px) {
    justify-content: center;
  }
`;

export const SaveButtonContainer = styled.div``;

export const NotifyInnerContainer = styled.div`
  position: relative;
  display: flex;
  align-items: center;
`;

export const GenderAgeContainer = styled.div`
  display: flex;

  @media (max-width: 1335px) {
    flex-direction: column;
  }
`;

export const DateOfBirthPickerContainer = styled.div`
  padding-top: 0.6rem;
  margin-bottom: 2.6rem;

  &:hover > div:first-child {
    color: #292929;
  }

  @media (max-width: 1335px) {
    margin-left: 0;
  }
`;

export const DateOfBirthPickerTitle = styled.div`
  display: block;
  font-size: 1.4rem;
  font-weight: 400;
  line-height: 1rem;
  margin-bottom: 1.2rem;
  color: #676767;

  &:hover {
    color: #292929;
  }
`;

export const DateOfBirthPickerInputContainer = styled.div`
  display: inline-flex;

  input[type='number']::-webkit-outer-spin-button,
  input[type='number']::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }

  input[type='number'] {
    -moz-appearance: textfield; /* Firefox */
  }
`;

export const DateOfBirthInputSeparator = styled.div`
  display: flex;
  align-items: center;
  width: 0.6rem;
  margin: 0 0.5rem;
`;

export const DateOfBirthInputSeparatorContent = styled.div`
  border: 1px solid black;
  border-color: #000000;
  width: 100%;
`;

export const CountryGenderContainer = styled.div`
  display: flex;

  @media (max-width: 1225px) {
    flex-direction: column;
  }
`;

export const CountryContainer = styled.div`
  width: 50%;
  padding-right: 2rem;
  margin-bottom: 2.7rem;

  @media (max-width: 1225px) {
    padding-right: 0;
    width: 100%;
  }
`;

export const SocialNetworkPickerContainer = styled.div`
  width: 35%;
  padding-left: 2rem;
  margin-bottom: 2.7rem;

  @media (max-width: 1000px) {
    padding-left: 0;
    width: 70%;
  }

  @media (max-width: 750px) {
    width: 80%;
  }

  @media (max-width: 600px) {
    width: 100%;
  }
`;

export const SocialNetworkPickerEntry = styled.div`
  display: flex;
  align-items: center;
`;

export const SocialNetworkPickerEntryName = styled.span`
  margin-left: 1rem;
  font-weight: 400;
`;

export const AddSocialAccountContainer = styled.div`
  display: flex;
  flex-direction: column;
  height: auto;
`;

export const AddSocialAccountButtonContainer = styled.div`
  display: flex;
  justify-content: end;
  align-items: start;
  margin-top: 3rem;
  margin-left: 3rem;

  @media (max-width: 1000px) {
    margin-left: auto;
  }
`;

export const HiddenElement = styled.div`
  visibility: hidden;
`;

export const TiktokSoonContainer = styled.div`
  position: absolute;
  top: 55%;
  right: 0.8rem;
`;
