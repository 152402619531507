/* eslint-disable consistent-return */
/* eslint-disable array-callback-return */
/* eslint-disable eqeqeq */
/* eslint-disable no-empty-function */
/* eslint-disable import/extensions */
import React, { createContext, useEffect, useMemo, useState } from 'react';
import { useTheme } from 'styled-components';
import { useLocation, useNavigate } from 'react-router-dom';
import { withTranslation } from 'react-i18next';
import ComboBox from 'components/shared/ComboBox/ComboBox';
import InputControlV2 from 'components/shared/InputControlV2/InputControlV2';
import MagnifyingGlassIcon from 'assets/iconComponents/MagnifyingGlassIcon';
import GlobeIcon from 'assets/iconComponents/Globe';
import InstagramIcon from 'assets/iconComponents/InstagramIcon';
import YoutubeIcon from 'assets/iconComponents/YoutubeIcon';

import * as S from './Styles';
import auth from '../../api/auth-helper';
import LoaderLocal from '../shared/LoaderLocal/LoaderLocal';
import CrossIcon from '../../assets/iconComponents/CrossIcon';
import NotifyCustom from '../shared/NotifyCustom/NotifyCustom';
import CheckMarkIcon from '../../assets/iconComponents/CheckMarkIcon';
import InputSpinnerIcon from '../../assets/iconComponents/InputSpinnerIcon/InputSpinnerIcon';
import { useIsOperatorOrPartnerAdmin } from './RQCustomHooks';
import {
  DEFAULT_USER_AVATAR,
  IBusinessProfile,
  PartnerTypes,
  PublicProfile,
  SocialNetworks,
} from '../../Services/Utils/types';
import { NotifyInnerContainer } from '../shared/NotifyCustom/Styles';
import {
  getSocialNetworkIcon,
  isAuthenticated,
  isNavbarDisplayed,
} from '../../Services/Utils/Utils';
import { IMenuNavbarV2Props } from './types';
import {
  useActivateBrandProfile,
  useGetBusinessProfiles,
  useGetSelfUser,
} from '../../Services/Utils/CustomHooks';
import GenericModal from '../shared/GenericModal/GenericModal';

const ClickOutHandler = require('react-onclickout');

export const OuterContext = createContext<{
  refreshNavbar: () => void;
  refetchBusinessProfilesByPartnerId: () => void;
}>({
  refreshNavbar: () => {},
  refetchBusinessProfilesByPartnerId: () => {},
});

const MenuNavbarV2: React.FC<IMenuNavbarV2Props> = (
  props: IMenuNavbarV2Props,
) => {
  const navigate = useNavigate();
  const location = useLocation();
  const themeGlobal = useTheme();
  const [tier, setTier] = useState('TIER1');
  const [toggleSideBar, setToggleSideBar] = useState(false);
  const [toggleDropdown, setToggleDropdown] = useState(false);
  const [toggleUserProfile, setToggleUserProfile] = useState(false);
  const [toggleMobileWorkSpace, setToggleMobileWorkSpace] = useState(false);
  const [showMediaKitSelectionModal, setShowMediaKitSelectionModal] =
    useState(false);
  const [socialAccountUsernameFilter, setSocialAccountUsernameFilter] =
    useState<string>('');
  const [socialAccountNetworkFilter, setSocialAccountNetworkFilter] =
    useState<string>('ALL');
  const socialNetworkAvailableValues = useMemo(() => {
    return [
      {
        value: 'ALL',
        label: (
          <S.SocialAccountNetworkFilterIcon globalEntry={true}>
            <GlobeIcon
              svgWidth="2rem"
              svgHeight="2rem"
              containerMarginRight="1rem"
            />
            {props.t('generic.all')}
          </S.SocialAccountNetworkFilterIcon>
        ),
      },
      {
        value: SocialNetworks.INSTAGRAM,
        label: (
          <S.SocialAccountNetworkFilterIcon>
            <InstagramIcon
              svgWidth="2rem"
              svgHeight="2rem"
              gradientColored={true}
              containerMarginRight="1rem"
            />
            {props.t('socialNetworks.INSTAGRAM')}
          </S.SocialAccountNetworkFilterIcon>
        ),
      },
      {
        value: SocialNetworks.YOUTUBE,
        label: (
          <S.SocialAccountNetworkFilterIcon>
            <YoutubeIcon
              svgWidth="2rem"
              svgHeight="2rem"
              containerMarginRight="1rem"
            />
            {props.t('socialNetworks.YOUTUBE')}
          </S.SocialAccountNetworkFilterIcon>
        ),
      },
    ];
  }, []);
  const [
    showSuccessfulActionNotification,
    setShowSuccessfulActionNotification,
  ] = useState<boolean>(false);
  const [
    showUnsuccessfulActionNotification,
    setShowUnsuccessfulActionNotification,
  ] = useState<boolean>(false);
  const [
    successfulActionNotificationMessage,
    setSuccessfulActionNotificationMessage,
  ] = useState('');
  const [
    unsuccessfulActionNotificationMessage,
    setUnsuccessfulActionNotificationMessage,
  ] = useState('');
  const [
    loadingActionNotificationMessage,
    setLoadingActionNotificationMessage,
  ] = useState('');
  const [_, setForceRenderCounter] = useState(0);
  const {
    data: isOperatorOrPartnerAdmin,
    isSuccess: isSuccessIsOperatorOrPartnerAdmin,
    isFetching: isFetchingSsIsOperatorOrPartnerAdmin,
    isError: isErrorIsOperatorOrPartnerAdmin,
  } = useIsOperatorOrPartnerAdmin();
  const {
    data: businessProfiles,
    isFetching: isFetchingBusinessProfiles,
    isError: isErrorBusinessProfiles,
    refetch: refetchGetBusinessProfiles,
  } = useGetBusinessProfiles();
  const {
    isLoading: isLoadingActivateBrandProfile,
    isSuccess: isSuccessActivateBrandProfile,
    isError: isErrorActivateBrandProfile,
    mutate: mutateActivateBrandProfile,
  } = useActivateBrandProfile();
  const {
    data: selfUser,
    isSuccess: isSuccessGetSelfUser,
    isFetching: isFetchingGetSelfUser,
    isError: isErrorGetSelfUser,
  } = useGetSelfUser(true);
  const [publicProfiles, setPublicProfiles] = useState<PublicProfile[]>([]);

  useEffect(() => {
    if (isSuccessGetSelfUser) {
      if (selfUser.publicProfiles?.length) {
        setPublicProfiles(
          selfUser.publicProfiles?.sort((profileA, profileB) =>
            profileA.username < profileB.username ? -1 : 1,
          ),
        );
      }
    }
  }, [isSuccessGetSelfUser]);

  useEffect(() => {
    if (isSuccessActivateBrandProfile) {
      setSuccessfulActionNotificationMessage(
        props.t('businessProfile.brandProfileActivated'),
      );
      setShowSuccessfulActionNotification(true);
      setTimeout(() => {
        setShowSuccessfulActionNotification(false);
      }, 2000);
    }
  }, [isSuccessActivateBrandProfile]);

  useEffect(() => {
    if (isErrorActivateBrandProfile) {
      setUnsuccessfulActionNotificationMessage(
        props.t('businessProfile.couldNotActivateBrandProfile'),
      );
      setShowUnsuccessfulActionNotification(true);
      setTimeout(() => {
        setShowUnsuccessfulActionNotification(false);
      }, 2000);
    }
  }, [isErrorActivateBrandProfile]);

  const getSocialAccountsMediaKitsSelectionComponent = () => {
    if (!selfUser) {
      return <></>;
    }

    if (selfUser!.publicProfiles?.length == 0) {
      return (
        <S.SocialAccountsEmpty>
          <div>{props.t('menu-navbar.noSocialAccountsPrimaryMessage')}</div>
          <S.SocialAccountsEmptySecondaryMessage>
            <S.SocialAccountsEmptyLink
              onClick={() => {
                navigate('/social-accounts-management');
                setShowMediaKitSelectionModal(false);
              }}
            >
              {props.t('menu-navbar.noSocialAccountsSecondaryMessagePart1')}
            </S.SocialAccountsEmptyLink>
            {props.t('menu-navbar.noSocialAccountsSecondaryMessagePart2')}
          </S.SocialAccountsEmptySecondaryMessage>
        </S.SocialAccountsEmpty>
      );
    }

    return (
      <S.SocialAccountsInnerContainer>
        <S.SocialAccountsFiltersRow>
          <S.SocialAccountUsernameFilterInputContainer>
            <InputControlV2
              value={socialAccountUsernameFilter}
              onChange={(inputValue: string) =>
                setSocialAccountUsernameFilter(inputValue)
              }
              inputFieldContainerWidth={'100%'}
              placeholder={props.t('generic.searchByUsernamePlaceholder')}
              inputFieldLineHeight={'2.5rem'}
              inputFieldPaddingTop={'1rem'}
              inputFieldPaddingBottom={'1rem'}
              inputFieldPaddingRight={'5rem'}
            />
            <S.SocialAccountUsernameFilterMagnifyingGlassContainer>
              <MagnifyingGlassIcon
                containerWidth="3rem"
                containerHeight="3rem"
              />
            </S.SocialAccountUsernameFilterMagnifyingGlassContainer>
          </S.SocialAccountUsernameFilterInputContainer>

          <S.SocialAccountNetworkFilterContainer>
            <S.SocialAccountNetworkFilterInnerContainer>
              <ComboBox
                width={'100%'}
                type={'single'}
                height={'4.4rem'}
                growWithoutScroll
                value={socialAccountNetworkFilter}
                valueKey={'network'}
                borderRadius={'0.6rem'}
                valuesAvailable={socialNetworkAvailableValues}
                isClearable={false}
                isFilterable={false}
                onChange={(obj: any) => {
                  setSocialAccountNetworkFilter(obj.network);
                }}
                optionContainerBorderRadius={'0.6rem'}
                availableOptionsContainerBorderRadius={'0.6rem'}
                innerOptionsContainerPaddingTop={'1.2rem'}
                innerOptionsContainerPaddingBottom={'1.2rem'}
                innerOptionsContainerPaddingLeft={'1.2rem'}
                innerOptionsContainerPaddingRight={'1.2rem'}
                innerOptionsContainerTextAlign={'start'}
                optionContainerPaddingTop={'1rem'}
                optionContainerPaddingBottom={'1rem'}
                optionContainerPaddingLeft={'1rem'}
                optionContainerPaddingRight={'1rem'}
                innerOptionsContainerMaxHeight={'25rem'}
                optionSelectedContainerPaddingLeft={'0'}
                changeBackgroundColorOnHover={false}
                themeStyles={(themeGlobal as any).comboBoxTopicsOfInterest}
              />
            </S.SocialAccountNetworkFilterInnerContainer>
          </S.SocialAccountNetworkFilterContainer>
        </S.SocialAccountsFiltersRow>

        {publicProfiles
          ?.filter(
            publicProfile =>
              publicProfile.username.includes(socialAccountUsernameFilter) &&
              (socialAccountNetworkFilter === 'ALL' ||
                publicProfile.socialNetwork === socialAccountNetworkFilter),
          )
          ?.map(publicProfile => (
            <S.SocialAccountContainer
              onClick={() => {
                navigate(`/user-media-kit/${publicProfile.publicUserId}`);
                setShowMediaKitSelectionModal(false);
                setToggleUserProfile(false);
              }}
            >
              <S.SocialAccountLeftContainer>
                <S.SocialAccountImage
                  src={publicProfile.displayPicture ?? DEFAULT_USER_AVATAR}
                />
                <S.SocialAccountNetworkIconContainer>
                  {getSocialNetworkIcon({
                    socialNetwork: publicProfile.socialNetwork,
                    width: '2rem',
                    height: '2rem',
                    gradientColored: true,
                  })}
                </S.SocialAccountNetworkIconContainer>
              </S.SocialAccountLeftContainer>

              <S.SocialAccountRightContainer>
                <S.SocialAccountUsername>
                  {`@${publicProfile.username}`}
                </S.SocialAccountUsername>
                <S.SocialAccountNetworkName>
                  {publicProfile.socialNetwork[0] +
                    publicProfile.socialNetwork.slice(1).toLowerCase()}
                </S.SocialAccountNetworkName>
              </S.SocialAccountRightContainer>
            </S.SocialAccountContainer>
          ))}
      </S.SocialAccountsInnerContainer>
    );
  };

  return (
    <>
      <S.MainNavbar
        active={toggleSideBar}
        showNavBar={isNavbarDisplayed(location.pathname)}
      >
        <S.LogoLink>
          <img src={require('../../assets/skorr-logo.png')} />
        </S.LogoLink>

        <S.WebappNav>
          <a>
            <S.NavItem
              active={location.pathname.includes('my-analytics')}
              onClick={() => navigate('/my-analytics')}
            >
              <S.NotificationDot />
              <S.Hoverbox>
                <S.NavIcon>
                  <svg version="1.1" viewBox="0 0 32 32">
                    <path d="M13.2 27.5h-6.8v-10.8h-4.1l13.7-12.2 13.7 12.2h-4.1v10.8h-6.8v-8.1h-5.5l-0.1 8.1z"></path>
                  </svg>
                </S.NavIcon>
                <S.NavLabel>{props.t('menu-navbar.myAnalytic')}</S.NavLabel>
              </S.Hoverbox>
            </S.NavItem>
          </a>

          <a>
            <S.NavItem
              active={location.pathname.includes('discover')}
              onClick={() => navigate('/discover')}
            >
              <S.NotificationDot />
              <S.Hoverbox>
                <S.NavIcon>
                  <svg version="1.1" viewBox="0 0 32 32">
                    <path d="M14.665 10.711c0 2.195-1.759 3.967-3.954 3.967s-3.967-1.772-3.967-3.967 1.772-3.967 3.967-3.967 3.954 1.772 3.954 3.967zM25.243 10.711c0 2.195-1.759 3.967-3.954 3.967s-3.967-1.772-3.967-3.967c0-2.195 1.772-3.967 3.967-3.967s3.954 1.772 3.954 3.967zM10.711 17.322c-3.081 0-9.256 1.547-9.256 4.628v3.306h18.512v-3.306c0-3.081-6.175-4.628-9.256-4.628zM20.007 17.388c0.463-0.040 0.899-0.066 1.283-0.066 3.081 0 9.256 1.547 9.256 4.628v3.306h-7.934v-3.306c0-1.957-1.071-3.451-2.605-4.562z" />
                  </svg>
                </S.NavIcon>
                <S.NavLabel>{props.t('menu-navbar.discover')}</S.NavLabel>
              </S.Hoverbox>
            </S.NavItem>
          </a>

          <a>
            <S.NavItem
              active={location.pathname.includes(
                'view-media-kit-configurations-list',
              )}
              onClick={() => navigate('/view-media-kit-configurations-list')}
            >
              <S.NotificationDot />
              <S.Hoverbox>
                <S.NavIcon>
                  <svg version="1.1" viewBox="0 0 32 32">
                    <path d="M22.629 28.648c-0.44 0-0.855-0.105-1.222-0.292l0.015 0.007-5.407-2.691-5.439 2.691c-0.352 0.18-0.767 0.285-1.207 0.285-1.493 0-2.703-1.21-2.703-2.703 0-0.17 0.016-0.337 0.046-0.499l-0.003 0.017 0.996-5.491-4.172-3.867c-0.538-0.495-0.874-1.203-0.874-1.989 0-1.365 1.012-2.493 2.327-2.676l0.014-0.002 5.885-0.809 2.713-5.177c0.459-0.87 1.357-1.452 2.391-1.452 0.003 0 0.006 0 0.009 0h-0.001c0.003 0 0.006 0 0.009 0 1.034 0 1.932 0.583 2.384 1.437l0.007 0.015 2.689 5.159 5.912 0.828c1.328 0.185 2.339 1.314 2.339 2.678 0 0.786-0.335 1.493-0.871 1.987l-0.002 0.002-4.159 3.837 0.984 5.523c0.027 0.144 0.043 0.31 0.043 0.48 0 1.493-1.208 2.703-2.7 2.707l-0.003-0.005zM16 23.004c0.002 0 0.003 0 0.005 0 0.435 0 0.846 0.103 1.21 0.286l-0.016-0.007 5.409 2.692-0.944-5.527c-0.028-0.146-0.044-0.315-0.044-0.487 0-0.784 0.335-1.49 0.871-1.982l0.002-0.002 4.159-3.836-5.904-0.871c-0.886-0.127-1.624-0.667-2.021-1.415l-0.007-0.015-2.688-5.156h-0.063l-2.691 5.157c-0.404 0.762-1.142 1.302-2.014 1.426l-0.014 0.002-5.885 0.809 4.133 3.897c0.537 0.494 0.872 1.2 0.872 1.984 0 0.172-0.016 0.34-0.047 0.502l0.003-0.017-0.993 5.491 5.459-2.653c0.35-0.177 0.764-0.28 1.201-0.28 0.002 0 0.005 0 0.007 0v0.002z"></path>
                  </svg>
                </S.NavIcon>
                <S.NavLabel>
                  {props.t('menu-navbar.media-kit-configurations-entry')}
                </S.NavLabel>
              </S.Hoverbox>
            </S.NavItem>
          </a>

          <a>
            <S.NavItem
              active={location.pathname.includes('lists')}
              onClick={() => navigate('/lists')}
            >
              <S.NotificationDot />
              <S.Hoverbox>
                <S.NavIcon>
                  <svg version="1.1" viewBox="0 0 32 32">
                    <path d="M28.8 25.6c0 0.96-0.64 1.6-1.44 1.6h-14.72c-0.8 0-1.44-0.64-1.44-1.6s0.64-1.6 1.44-1.6h14.72c0.8 0 1.44 0.64 1.44 1.6zM27.36 14.4h-14.72c-0.8 0-1.44 0.64-1.44 1.6s0.64 1.6 1.44 1.6h14.72c0.8 0 1.44-0.64 1.44-1.6s-0.64-1.6-1.44-1.6zM27.36 4.8h-14.72c-0.8 0-1.44 0.64-1.44 1.6s0.64 1.6 1.44 1.6h14.72c0.8 0 1.44-0.64 1.44-1.6s-0.64-1.6-1.44-1.6zM5.6 4c-1.28 0-2.4 1.12-2.4 2.4s1.12 2.4 2.4 2.4 2.4-1.12 2.4-2.4-1.12-2.4-2.4-2.4zM5.6 13.6c-1.28 0-2.4 1.12-2.4 2.4s1.12 2.4 2.4 2.4 2.4-1.12 2.4-2.4-1.12-2.4-2.4-2.4zM5.6 23.2c-1.28 0-2.4 1.12-2.4 2.4s1.12 2.4 2.4 2.4 2.4-1.12 2.4-2.4c0-1.28-1.12-2.4-2.4-2.4z"></path>
                  </svg>
                </S.NavIcon>
                <S.NavLabel>{props.t('generic.lists')}</S.NavLabel>
              </S.Hoverbox>
            </S.NavItem>
          </a>
        </S.WebappNav>

        <S.NavHelp>
          <S.NavHelpInner
            onClick={() => window.open('https://skorr.social/faq/', '_blank')}
          >
            <S.HelpIcon>
              <svg version="1.1" viewBox="0 0 32 32">
                <path d="M16 4c-4.948 0-9 3.601-9 8h4.5c0-2.235 1.987-4 4.5-4s4.5 1.765 4.5 4c0 1.531-1.108 2.891-2.743 3.375l-0.915 0.251c-1.837 0.539-3.093 2.101-3.093 3.812v2.563h4.5v-2.563l0.915-0.251c3.463-1.023 5.836-3.944 5.836-7.187 0-4.399-4.052-8-9-8zM13.749 24v4h4.5v-4h-4.5z" />
              </svg>
            </S.HelpIcon>
            <S.HelpText>{props.t('menu-navbar.help')}</S.HelpText>
          </S.NavHelpInner>
        </S.NavHelp>
      </S.MainNavbar>

      <S.TopBar
        active={toggleSideBar}
        showNavBar={isNavbarDisplayed(location.pathname)}
      >
        <S.TopBarLeft showNavBar={true}>
          <S.MobileLogo>
            <img src={require('../../assets/skorr-logo.png')} />
          </S.MobileLogo>

          <S.ToggleMainbar onClick={() => setToggleSideBar(!toggleSideBar)}>
            <svg version="1.1" viewBox="0 0 32 32">
              <path d="M4.8 8c7.52 0 14.88 0 22.4 0zM28.8 8c0-0.96-0.64-1.6-1.6-1.6h-22.4c-0.96 0-1.6 0.64-1.6 1.6s0.64 1.6 1.6 1.6h22.4c0.96 0 1.6-0.64 1.6-1.6zM4.8 24c3.2 0 19.2 0 22.4 0zM28.8 24c0-0.96-0.64-1.6-1.6-1.6h-22.4c-0.96 0-1.6 0.64-1.6 1.6s0.64 1.6 1.6 1.6h22.4c0.96 0 1.6-0.64 1.6-1.6zM4.8 16c5.28 0 17.12 0 22.4 0zM28.8 16c0-0.96-0.64-1.6-1.6-1.6h-22.4c-0.96 0-1.6 0.64-1.6 1.6s0.64 1.6 1.6 1.6h22.4c0.96 0 1.6-0.64 1.6-1.6z" />
            </svg>
          </S.ToggleMainbar>

          {isAuthenticated()?.partnerType !== PartnerTypes.influencer && (
            <S.BrandDropdown
              isOperatorOrPartnerAdmin={
                isSuccessIsOperatorOrPartnerAdmin && isOperatorOrPartnerAdmin
              }
              onClick={(e: any) => {
                if (
                  isSuccessIsOperatorOrPartnerAdmin &&
                  isOperatorOrPartnerAdmin
                ) {
                  if (!e.target.closest('#left-brand-avatar-container')) {
                    setTimeout(() => setToggleDropdown(!toggleDropdown), 0);
                  }
                } else {
                  setTimeout(() => setToggleDropdown(!toggleDropdown), 0);
                }
              }}
            >
              <LoaderLocal show={isFetchingBusinessProfiles}>
                <S.BrandProfileSelected>
                  <S.BrandAvatar
                    isOperatorOrPartnerAdmin={
                      isSuccessIsOperatorOrPartnerAdmin &&
                      isOperatorOrPartnerAdmin
                    }
                    id={'left-brand-avatar-container'}
                  >
                    {isSuccessIsOperatorOrPartnerAdmin &&
                      isOperatorOrPartnerAdmin && (
                        <S.BrandAvatarEdit
                          onClick={(e: any) => {
                            navigate(
                              `/business-profile/edit/${
                                isAuthenticated()?.businessProfileId
                              }`,
                            );
                          }}
                        >
                          <svg version="1.1" viewBox="0 0 32 32">
                            <path d="M21.179 4.946c0.821-0.821 1.879-1.232 2.937-1.232s2.116 0.411 2.937 1.232c1.642 1.642 1.642 4.232 0 5.874l-15.98 15.98-0.316 0.063-5.558 1.105-1.484 0.316 0.316-1.484 1.105-5.558 0.063-0.316 15.98-15.98zM25.632 6.368c-0.494-0.494-1.007-0.726-1.516-0.726s-1.022 0.233-1.516 0.726l-0.695 0.726 3 3 0.726-0.695c0.983-0.983 0.983-2.049 0-3.032zM20.485 8.515l-12.222 12.222c1.295 0.663 2.337 1.705 3 3l12.222-12.222-3-3zM9.62 25.032c-0.489-1.212-1.441-2.163-2.653-2.653l-0.663 3.316 3.316-0.663z" />
                          </svg>
                        </S.BrandAvatarEdit>
                      )}

                    <S.BrandAvatarEmpty>
                      <S.BrandAvatarUserImg
                        src={
                          isAuthenticated()?.businessProfileUserImage ??
                          DEFAULT_USER_AVATAR
                        }
                      />
                    </S.BrandAvatarEmpty>
                  </S.BrandAvatar>

                  <S.BrandName
                    isOperatorOrPartnerAdmin={
                      isSuccessIsOperatorOrPartnerAdmin &&
                      isOperatorOrPartnerAdmin
                    }
                  >
                    <S.LabelBrandname>
                      {isAuthenticated()?.businessProfile}
                    </S.LabelBrandname>
                  </S.BrandName>

                  {isSuccessIsOperatorOrPartnerAdmin &&
                    isOperatorOrPartnerAdmin && (
                      <S.BrandDropdownIcon active={toggleDropdown}>
                        <svg version="1.1" viewBox="0 0 32 32">
                          <path d="M8.656 10.072l7.344 7.328 7.344-7.328 2.256 2.256-9.6 9.6-9.6-9.6 2.256-2.256z"></path>
                        </svg>
                      </S.BrandDropdownIcon>
                    )}
                </S.BrandProfileSelected>
              </LoaderLocal>
            </S.BrandDropdown>
          )}

          {isSuccessIsOperatorOrPartnerAdmin && isOperatorOrPartnerAdmin && (
            <ClickOutHandler onClickOut={() => setToggleDropdown(false)}>
              <S.BrandDropdownMenuOuter active={toggleDropdown}>
                <S.BrandDropdownMenu>
                  {businessProfiles?.map((bp: IBusinessProfile) => {
                    if (isAuthenticated()?.businessProfileId !== bp._id) {
                      return (
                        <S.BrandProfile
                          onClick={() => {
                            mutateActivateBrandProfile(bp._id);
                            setLoadingActionNotificationMessage(
                              props.t('businessProfile.activatingBrandProfile'),
                            );
                            setToggleDropdown(!toggleDropdown);
                          }}
                        >
                          <S.BrandAvatar
                            isOperatorOrPartnerAdmin={
                              isSuccessIsOperatorOrPartnerAdmin &&
                              isOperatorOrPartnerAdmin
                            }
                          >
                            <S.BrandAvatarEdit
                              onClick={(e: any) => {
                                e.stopPropagation();
                                navigate(`/business-profile/edit/${bp._id}`);
                                setToggleDropdown(!toggleDropdown);
                              }}
                            >
                              <svg version="1.1" viewBox="0 0 32 32">
                                <path d="M21.179 4.946c0.821-0.821 1.879-1.232 2.937-1.232s2.116 0.411 2.937 1.232c1.642 1.642 1.642 4.232 0 5.874l-15.98 15.98-0.316 0.063-5.558 1.105-1.484 0.316 0.316-1.484 1.105-5.558 0.063-0.316 15.98-15.98zM25.632 6.368c-0.494-0.494-1.007-0.726-1.516-0.726s-1.022 0.233-1.516 0.726l-0.695 0.726 3 3 0.726-0.695c0.983-0.983 0.983-2.049 0-3.032zM20.485 8.515l-12.222 12.222c1.295 0.663 2.337 1.705 3 3l12.222-12.222-3-3zM9.62 25.032c-0.489-1.212-1.441-2.163-2.653-2.653l-0.663 3.316 3.316-0.663z" />
                              </svg>
                            </S.BrandAvatarEdit>

                            <S.BrandAvatarEmpty>
                              {bp.displayPicture ? (
                                <S.BrandAvatarUserImg src={bp.displayPicture} />
                              ) : (
                                <svg version="1.1" viewBox="0 0 32 32">
                                  <path d="M3.667 24.286c0-5.524 0-11.049 0-16.573 0.015-0.036 0.035-0.072 0.046-0.109 0.206-0.741 0.782-1.193 1.553-1.193 7.154-0.002 14.308-0.003 21.462 0 0.939 0 1.605 0.675 1.605 1.616 0.002 5.315 0.002 10.631-0.002 15.946-0 0.181-0.028 0.37-0.083 0.543-0.213 0.675-0.799 1.074-1.568 1.074-7.122 0.001-14.244 0.001-21.366 0.001-0.040 0-0.080 0-0.12-0.002-0.603-0.029-1.053-0.309-1.341-0.837-0.079-0.145-0.124-0.31-0.185-0.466zM5.252 19.001c0.084-0.091 0.137-0.146 0.187-0.204 1.822-2.117 3.643-4.235 5.467-6.35 0.594-0.688 1.372-0.68 1.953 0.015 1.932 2.307 3.864 4.616 5.795 6.924 0.091 0.108 0.183 0.216 0.308 0.364 0.074-0.096 0.12-0.173 0.182-0.235 0.947-0.949 1.89-1.902 2.848-2.84 0.166-0.162 0.382-0.302 0.601-0.374 0.413-0.136 0.791-0.009 1.111 0.282 0.945 0.857 1.89 1.716 2.834 2.574 0.060 0.055 0.123 0.106 0.211 0.183 0-3.815 0-7.577 0-11.354-7.172 0-14.328 0-21.499 0-0 3.664-0 7.316-0 11.015zM24.42 12.079c-0.005-1.18-0.97-2.144-2.149-2.147-1.19-0.003-2.167 0.971-2.162 2.157 0.004 1.182 0.964 2.139 2.148 2.144 1.193 0.004 2.168-0.967 2.163-2.154z" />
                                </svg>
                              )}
                            </S.BrandAvatarEmpty>
                          </S.BrandAvatar>

                          <S.BrandName>
                            <S.LabelBrandname>{bp.name}</S.LabelBrandname>
                          </S.BrandName>
                        </S.BrandProfile>
                      );
                    }
                  })}
                </S.BrandDropdownMenu>
                <S.BrandProfileCta>
                  <a
                    onClick={() => {
                      navigate('/business-profile/create');
                      setToggleDropdown(!toggleDropdown);
                    }}
                  >
                    {props.t('partner.createNewProfile')}
                  </a>
                </S.BrandProfileCta>
              </S.BrandDropdownMenuOuter>
            </ClickOutHandler>
          )}
        </S.TopBarLeft>

        <S.TopBarRight>
          <LoaderLocal
            show={
              isFetchingSsIsOperatorOrPartnerAdmin ||
              isFetchingBusinessProfiles ||
              isFetchingGetSelfUser ||
              isErrorIsOperatorOrPartnerAdmin ||
              isErrorBusinessProfiles ||
              isErrorGetSelfUser
            }
            spinnerFontSize={'1.7rem'}
          >
            <S.UserAvatar
              onClick={(e: React.MouseEvent<HTMLDivElement>) => {
                setTimeout(() => setToggleUserProfile(!toggleUserProfile), 0);
              }}
            >
              <img
                src={
                  isAuthenticated()?.businessProfileUserImage ??
                  require('../../assets/blank_avatar_skorr.jpg')
                }
              />
            </S.UserAvatar>
          </LoaderLocal>

          <ClickOutHandler
            onClickOut={(e: any) => {
              if (!(e.target.id == 'generic-modal-close-button-id')) {
                setToggleUserProfile(false);
              }
            }}
          >
            <S.SidebarMenu active={toggleUserProfile}>
              <S.SidebarTop></S.SidebarTop>

              <S.SidebarBottom>
                <S.SidebarContent>
                  <div>
                    <S.SectionMenuContainer>
                      <>
                        <S.SectionMenuItem
                          onClick={() => setShowMediaKitSelectionModal(true)}
                        >
                          <svg version="1.1" viewBox="0 0 32 32">
                            <path d="M16 28c-0.736 0-1.333-0.596-1.333-1.333v-13.333c0-0.736 0.597-1.333 1.333-1.333 0.737 0 1.333 0.597 1.333 1.333v13.333c0 0.737-0.596 1.333-1.333 1.333zM25.333 26.667v-21.333c0-0.736-0.596-1.333-1.333-1.333s-1.333 0.597-1.333 1.333v21.333c0 0.737 0.596 1.333 1.333 1.333s1.333-0.596 1.333-1.333zM9.333 26.667v-5.333c0-0.737-0.597-1.333-1.333-1.333s-1.333 0.596-1.333 1.333v5.333c0 0.737 0.597 1.333 1.333 1.333s1.333-0.596 1.333-1.333z"></path>
                          </svg>
                          <S.SectionMenuItemText>
                            {props.t(
                              'menu-navbar.subPartnerOptions.myAnalytics',
                            )}
                          </S.SectionMenuItemText>
                        </S.SectionMenuItem>

                        <S.SectionMenuItem
                          onClick={() => {
                            navigate('/view-media-kit-configurations-list');
                            setToggleUserProfile(false);
                          }}
                        >
                          <svg version="1.1" viewBox="0 0 32 32">
                            <path d="M22.629 28.648c-0.44 0-0.855-0.105-1.222-0.292l0.015 0.007-5.407-2.691-5.439 2.691c-0.352 0.18-0.767 0.285-1.207 0.285-1.493 0-2.703-1.21-2.703-2.703 0-0.17 0.016-0.337 0.046-0.499l-0.003 0.017 0.996-5.491-4.172-3.867c-0.538-0.495-0.874-1.203-0.874-1.989 0-1.365 1.012-2.493 2.327-2.676l0.014-0.002 5.885-0.809 2.713-5.177c0.459-0.87 1.357-1.452 2.391-1.452 0.003 0 0.006 0 0.009 0h-0.001c0.003 0 0.006 0 0.009 0 1.034 0 1.932 0.583 2.384 1.437l0.007 0.015 2.689 5.159 5.912 0.828c1.328 0.185 2.339 1.314 2.339 2.678 0 0.786-0.335 1.493-0.871 1.987l-0.002 0.002-4.159 3.837 0.984 5.523c0.027 0.144 0.043 0.31 0.043 0.48 0 1.493-1.208 2.703-2.7 2.707l-0.003-0.005zM16 23.004c0.002 0 0.003 0 0.005 0 0.435 0 0.846 0.103 1.21 0.286l-0.016-0.007 5.409 2.692-0.944-5.527c-0.028-0.146-0.044-0.315-0.044-0.487 0-0.784 0.335-1.49 0.871-1.982l0.002-0.002 4.159-3.836-5.904-0.871c-0.886-0.127-1.624-0.667-2.021-1.415l-0.007-0.015-2.688-5.156h-0.063l-2.691 5.157c-0.404 0.762-1.142 1.302-2.014 1.426l-0.014 0.002-5.885 0.809 4.133 3.897c0.537 0.494 0.872 1.2 0.872 1.984 0 0.172-0.016 0.34-0.047 0.502l0.003-0.017-0.993 5.491 5.459-2.653c0.35-0.177 0.764-0.28 1.201-0.28 0.002 0 0.005 0 0.007 0v0.002z"></path>
                          </svg>

                          <S.SectionMenuItemText>
                            {props.t(
                              'menu-navbar.media-kit-configurations-entry',
                            )}
                          </S.SectionMenuItemText>
                        </S.SectionMenuItem>

                        <S.SectionMenuItem
                          onClick={() => {
                            navigate('/social-accounts-management');
                            setToggleUserProfile(false);
                          }}
                        >
                          <svg version="1.1" viewBox="0 0 32 32">
                            <path d="M24 20c-0.001 0-0.002 0-0.003 0-1.478 0-2.815 0.603-3.778 1.577l-0 0-7.107-4.14c0.14-0.43 0.221-0.924 0.221-1.437s-0.081-1.008-0.231-1.471l0.009 0.034 7.103-4.145c0.967 0.974 2.306 1.576 3.785 1.576 2.946 0 5.333-2.388 5.333-5.333s-2.388-5.333-5.333-5.333c-2.946 0-5.333 2.388-5.333 5.333 0 0.002 0 0.005 0 0.007v-0c0.008 0.515 0.088 1.009 0.231 1.475l-0.010-0.037-7.103 4.145c-0.967-0.977-2.309-1.583-3.792-1.583-2.946 0-5.333 2.388-5.333 5.333s2.388 5.333 5.333 5.333c1.483 0 2.825-0.605 3.791-1.582l0-0 7.104 4.133c-0.135 0.432-0.216 0.93-0.223 1.446l-0 0.004c0 2.946 2.388 5.333 5.333 5.333s5.333-2.388 5.333-5.333c0-2.946-2.388-5.333-5.333-5.333v0zM24 4c1.473 0 2.667 1.194 2.667 2.667s-1.194 2.667-2.667 2.667c-1.473 0-2.667-1.194-2.667-2.667v0c0-1.473 1.194-2.667 2.667-2.667v0zM8 18.667c-1.473 0-2.667-1.194-2.667-2.667s1.194-2.667 2.667-2.667c1.473 0 2.667 1.194 2.667 2.667v0c0 1.473-1.194 2.667-2.667 2.667v0zM24 28c-1.473 0-2.667-1.194-2.667-2.667v0c0.002-0.492 0.138-0.952 0.373-1.345l-0.007 0.012 0.012-0.015 0.007-0.017c0.474-0.785 1.322-1.301 2.291-1.301 1.473 0 2.667 1.194 2.667 2.667s-1.194 2.667-2.667 2.667c-0.003 0-0.007 0-0.010-0h0z"></path>
                          </svg>

                          <S.SectionMenuItemText>
                            {props.t(
                              'menu-navbar.subBusinessProfileOptions.changeSocialAccounts',
                            )}
                          </S.SectionMenuItemText>
                        </S.SectionMenuItem>
                      </>

                      {isAuthenticated()?.partnerType ===
                        PartnerTypes.influencer && (
                        <S.SectionMenuItem
                          onClick={() => {
                            navigate('/influencer/profile-edit');
                            setToggleUserProfile(false);
                          }}
                        >
                          <svg version="1.1" viewBox="0 0 32 32">
                            <path d="M21.179 4.946c0.821-0.821 1.879-1.232 2.937-1.232s2.116 0.411 2.937 1.232c1.642 1.642 1.642 4.232 0 5.874l-15.98 15.98-0.316 0.063-5.558 1.105-1.484 0.316 0.316-1.484 1.105-5.558 0.063-0.316 15.98-15.98zM25.632 6.368c-0.494-0.494-1.007-0.726-1.516-0.726s-1.022 0.233-1.516 0.726l-0.695 0.726 3 3 0.726-0.695c0.983-0.983 0.983-2.049 0-3.032zM20.485 8.515l-12.222 12.222c1.295 0.663 2.337 1.705 3 3l12.222-12.222-3-3zM9.62 25.032c-0.489-1.212-1.441-2.163-2.653-2.653l-0.663 3.316 3.316-0.663z"></path>
                          </svg>
                          <S.SectionMenuItemText>
                            {props.t(
                              'menu-navbar.subBusinessProfileOptions.editProfile',
                            )}
                          </S.SectionMenuItemText>
                        </S.SectionMenuItem>
                      )}

                      {isAuthenticated()?.partnerType !==
                        PartnerTypes.influencer &&
                        isSuccessIsOperatorOrPartnerAdmin &&
                        isOperatorOrPartnerAdmin && (
                          <S.SectionMenuItem
                            onClick={() => {
                              navigate('/business-profiles');
                              setToggleUserProfile(false);
                            }}
                          >
                            <svg version="1.1" viewBox="0 0 32 32">
                              <path d="M28.8 25.6c0 0.96-0.64 1.6-1.44 1.6h-14.72c-0.8 0-1.44-0.64-1.44-1.6s0.64-1.6 1.44-1.6h14.72c0.8 0 1.44 0.64 1.44 1.6zM27.36 14.4h-14.72c-0.8 0-1.44 0.64-1.44 1.6s0.64 1.6 1.44 1.6h14.72c0.8 0 1.44-0.64 1.44-1.6s-0.64-1.6-1.44-1.6zM27.36 4.8h-14.72c-0.8 0-1.44 0.64-1.44 1.6s0.64 1.6 1.44 1.6h14.72c0.8 0 1.44-0.64 1.44-1.6s-0.64-1.6-1.44-1.6zM5.6 4c-1.28 0-2.4 1.12-2.4 2.4s1.12 2.4 2.4 2.4 2.4-1.12 2.4-2.4-1.12-2.4-2.4-2.4zM5.6 13.6c-1.28 0-2.4 1.12-2.4 2.4s1.12 2.4 2.4 2.4 2.4-1.12 2.4-2.4-1.12-2.4-2.4-2.4zM5.6 23.2c-1.28 0-2.4 1.12-2.4 2.4s1.12 2.4 2.4 2.4 2.4-1.12 2.4-2.4c0-1.28-1.12-2.4-2.4-2.4z"></path>
                            </svg>
                            <S.SectionMenuItemText>
                              {props.t(
                                'menu-navbar.subBusinessProfileOptions.businessProfiles',
                              )}
                            </S.SectionMenuItemText>
                          </S.SectionMenuItem>
                        )}
                    </S.SectionMenuContainer>
                  </div>

                  {!(
                    auth.isAuthenticated().type != 'INFLUENCER' &&
                    !isOperatorOrPartnerAdmin
                  ) && (
                    <S.UserOptionsContainer>
                      <S.SectionLabel>
                        {props.t(
                          'menu-navbar.subBusinessProfileOptions.userOptions',
                        )}
                      </S.SectionLabel>
                      <S.SectionMenuContainer>
                        <S.SectionMenuItem
                          onClick={() => {
                            sessionStorage.removeItem('jwt');
                            navigate('/');
                            setToggleUserProfile(false);
                          }}
                        >
                          <svg version="1.1" viewBox="0 0 32 32">
                            <path d="M12.001 26.667h-5.333c-0.351 0-0.695-0.141-0.943-0.391-0.248-0.248-0.391-0.592-0.391-0.943v-18.667c0-0.351 0.143-0.695 0.391-0.943s0.591-0.391 0.943-0.391h5.333c0.736 0 1.333-0.597 1.333-1.333s-0.597-1.333-1.333-1.333h-5.333c-1.069 0-2.073 0.416-2.828 1.172-0.756 0.755-1.172 1.76-1.172 2.828v18.667c0 1.068 0.416 2.073 1.172 2.828 0.755 0.756 1.759 1.172 2.828 1.172h5.333c0.736 0 1.333-0.596 1.333-1.333s-0.597-1.333-1.333-1.333z"></path>
                            <path d="M29.232 16.509c0.135-0.325 0.135-0.692 0-1.019-0.068-0.164-0.165-0.311-0.289-0.435l-6.665-6.665c-0.521-0.521-1.364-0.521-1.885 0s-0.521 1.364 0 1.885l4.391 4.391h-12.781c-0.736 0-1.333 0.597-1.333 1.333 0 0.737 0.597 1.333 1.333 1.333h12.781l-4.391 4.391c-0.521 0.521-0.521 1.364 0 1.885 0.26 0.26 0.601 0.391 0.943 0.391s0.683-0.131 0.943-0.391l6.665-6.665c0.123-0.123 0.221-0.271 0.289-0.435z"></path>
                          </svg>
                          <S.SectionMenuItemText>
                            {props.t('menu-navbar.subPartnerOptions.logout')}
                          </S.SectionMenuItemText>
                        </S.SectionMenuItem>
                      </S.SectionMenuContainer>
                    </S.UserOptionsContainer>
                  )}

                  <S.LegalInformationContainer>
                    <S.SectionLabel>
                      {props.t('menu-navbar.legalInformation')}
                    </S.SectionLabel>
                    <S.SectionMenuContainer>
                      <S.SectionMenuItem
                        onClick={() => {
                          window.open(
                            'https://skorr.social/privacy-policy',
                            '_blank',
                          );
                        }}
                      >
                        <svg version="1.1" viewBox="0 0 32 32">
                          <path d="M16 30.667c-0.204 0-0.409-0.047-0.596-0.14-0.465-0.233-11.404-5.803-11.404-14.527v-9.333c0-0.556 0.345-1.053 0.865-1.249l10.667-4c0.303-0.113 0.635-0.113 0.937 0l10.667 4c0.519 0.196 0.864 0.693 0.864 1.249v9.333c0 8.724-10.937 14.293-11.404 14.527-0.187 0.093-0.392 0.14-0.596 0.14zM6.667 7.591v8.409c0 6.065 7.187 10.597 9.332 11.816 2.144-1.221 9.335-5.768 9.335-11.816v-8.409l-9.333-3.5-9.333 3.5z"></path>
                        </svg>
                        <S.SectionMenuItemText>
                          {props.t('menu-navbar.privacyPolicy')}
                        </S.SectionMenuItemText>
                        <svg
                          className="external-link-icon"
                          version="1.1"
                          viewBox="0 0 32 32"
                        >
                          <path d="M24 16c-0.737 0-1.333 0.597-1.333 1.333v8c0 0.351-0.141 0.695-0.391 0.943s-0.592 0.391-0.943 0.391h-14.667c-0.351 0-0.695-0.141-0.943-0.391-0.248-0.248-0.391-0.592-0.391-0.943v-14.667c0-0.351 0.143-0.695 0.391-0.943s0.592-0.391 0.943-0.391h8c0.736 0 1.333-0.597 1.333-1.333s-0.597-1.333-1.333-1.333h-8c-1.068 0-2.073 0.416-2.828 1.172-0.756 0.755-1.172 1.76-1.172 2.828v14.667c0 1.068 0.416 2.073 1.172 2.828 0.755 0.756 1.76 1.172 2.828 1.172h14.667c1.068 0 2.073-0.416 2.828-1.172s1.172-1.76 1.172-2.828v-8c0-0.736-0.596-1.333-1.333-1.333z"></path>
                          <path d="M29.231 3.491c-0.135-0.327-0.395-0.587-0.721-0.721-0.163-0.067-0.336-0.103-0.509-0.103h-8c-0.737 0-1.333 0.597-1.333 1.333s0.596 1.333 1.333 1.333h4.781l-12.391 12.391c-0.521 0.521-0.521 1.364 0 1.885 0.26 0.26 0.601 0.391 0.943 0.391s0.683-0.131 0.943-0.391l12.391-12.391v4.781c0 0.736 0.596 1.333 1.333 1.333s1.333-0.597 1.333-1.333v-8c0-0.173-0.035-0.347-0.103-0.509z"></path>
                        </svg>
                      </S.SectionMenuItem>

                      <S.SectionMenuItem
                        onClick={() => {
                          window.open('https://skorr.social/terms', '_blank');
                        }}
                      >
                        <svg version="1.1" viewBox="0 0 32 32">
                          <path d="M27.896 10.153c-0.068-0.163-0.165-0.308-0.288-0.431l-7.997-7.997c-0.123-0.123-0.268-0.22-0.431-0.288-0.161-0.068-0.335-0.104-0.513-0.104h-10.667c-1.068 0-2.073 0.416-2.828 1.172-0.756 0.755-1.172 1.76-1.172 2.828v21.333c0 1.068 0.416 2.073 1.172 2.828 0.755 0.756 1.76 1.172 2.828 1.172h16c1.068 0 2.073-0.416 2.828-1.172s1.172-1.76 1.172-2.828v-16c0-0.177-0.036-0.352-0.104-0.513zM20 5.885l3.448 3.448h-3.448v-3.448zM24.943 27.609c-0.248 0.249-0.592 0.391-0.943 0.391h-16c-0.351 0-0.695-0.141-0.943-0.391-0.248-0.248-0.391-0.592-0.391-0.943v-21.333c0-0.351 0.143-0.695 0.391-0.943s0.592-0.391 0.943-0.391h9.333v6.667c0 0.736 0.596 1.333 1.333 1.333h6.667v14.667c0 0.351-0.141 0.695-0.391 0.943z"></path>
                          <path d="M21.333 16h-10.667c-0.736 0-1.333 0.596-1.333 1.333s0.597 1.333 1.333 1.333h10.667c0.737 0 1.333-0.596 1.333-1.333s-0.596-1.333-1.333-1.333z"></path>
                          <path d="M21.333 21.333h-10.667c-0.736 0-1.333 0.596-1.333 1.333s0.597 1.333 1.333 1.333h10.667c0.737 0 1.333-0.596 1.333-1.333s-0.596-1.333-1.333-1.333z"></path>
                          <path d="M10.667 13.333h2.667c0.736 0 1.333-0.597 1.333-1.333s-0.597-1.333-1.333-1.333h-2.667c-0.736 0-1.333 0.597-1.333 1.333s0.597 1.333 1.333 1.333z"></path>
                        </svg>
                        <S.SectionMenuItemText>
                          {props.t('signin.generic.termsAndConditions')}
                        </S.SectionMenuItemText>
                        <svg
                          className="external-link-icon"
                          version="1.1"
                          viewBox="0 0 32 32"
                        >
                          <path d="M24 16c-0.737 0-1.333 0.597-1.333 1.333v8c0 0.351-0.141 0.695-0.391 0.943s-0.592 0.391-0.943 0.391h-14.667c-0.351 0-0.695-0.141-0.943-0.391-0.248-0.248-0.391-0.592-0.391-0.943v-14.667c0-0.351 0.143-0.695 0.391-0.943s0.592-0.391 0.943-0.391h8c0.736 0 1.333-0.597 1.333-1.333s-0.597-1.333-1.333-1.333h-8c-1.068 0-2.073 0.416-2.828 1.172-0.756 0.755-1.172 1.76-1.172 2.828v14.667c0 1.068 0.416 2.073 1.172 2.828 0.755 0.756 1.76 1.172 2.828 1.172h14.667c1.068 0 2.073-0.416 2.828-1.172s1.172-1.76 1.172-2.828v-8c0-0.736-0.596-1.333-1.333-1.333z"></path>
                          <path d="M29.231 3.491c-0.135-0.327-0.395-0.587-0.721-0.721-0.163-0.067-0.336-0.103-0.509-0.103h-8c-0.737 0-1.333 0.597-1.333 1.333s0.596 1.333 1.333 1.333h4.781l-12.391 12.391c-0.521 0.521-0.521 1.364 0 1.885 0.26 0.26 0.601 0.391 0.943 0.391s0.683-0.131 0.943-0.391l12.391-12.391v4.781c0 0.736 0.596 1.333 1.333 1.333s1.333-0.597 1.333-1.333v-8c0-0.173-0.035-0.347-0.103-0.509z"></path>
                        </svg>
                      </S.SectionMenuItem>

                      <S.SectionMenuItemHelpMobile onClick={() => {}}>
                        <svg version="1.1" viewBox="0 0 32 32">
                          <path d="M16 30.667c-8.087 0-14.667-6.58-14.667-14.667s6.58-14.667 14.667-14.667 14.667 6.58 14.667 14.667-6.58 14.667-14.667 14.667zM16 4c-6.616 0-12 5.384-12 12 0 6.617 5.384 12 12 12 6.617 0 12-5.383 12-12 0-6.616-5.383-12-12-12zM16.309 18.599c0.503-0.167 4.912-1.739 4.912-5.263 0.001-1.257-0.443-2.48-1.252-3.443-0.809-0.961-1.939-1.609-3.18-1.823-1.239-0.212-2.519 0.021-3.603 0.659-1.087 0.639-1.915 1.643-2.331 2.829-0.244 0.695 0.121 1.456 0.816 1.7 0.696 0.247 1.456-0.121 1.7-0.816 0.208-0.593 0.621-1.095 1.164-1.413s1.185-0.436 1.801-0.331c0.621 0.107 1.187 0.431 1.589 0.912 0.407 0.481 0.629 1.092 0.628 1.723 0 1.379-2.289 2.465-3.088 2.736-0.699 0.232-1.076 0.987-0.843 1.687 0.187 0.559 0.707 0.912 1.265 0.912 0.137 0 0.279-0.023 0.42-0.069zM17.336 22.667c0-0.737-0.596-1.333-1.333-1.333h-0.013c-0.736 0-1.327 0.596-1.327 1.333s0.604 1.333 1.34 1.333c0.737 0 1.333-0.596 1.333-1.333z"></path>
                        </svg>
                        <S.SectionMenuItemText>
                          {props.t('menu-navbar.help&support')}
                        </S.SectionMenuItemText>
                      </S.SectionMenuItemHelpMobile>
                    </S.SectionMenuContainer>
                  </S.LegalInformationContainer>
                </S.SidebarContent>
              </S.SidebarBottom>
            </S.SidebarMenu>
          </ClickOutHandler>
        </S.TopBarRight>
      </S.TopBar>

      <S.MainContainer
        showSideBar={!toggleSideBar}
        showNavbar={isNavbarDisplayed(location.pathname)}
      >
        <S.MainContainerInner>
          <OuterContext.Provider
            value={{
              refreshNavbar: () =>
                setForceRenderCounter(prevState => prevState + 1),
              refetchBusinessProfilesByPartnerId: () =>
                refetchGetBusinessProfiles(),
            }}
          >
            {props.children}
          </OuterContext.Provider>
        </S.MainContainerInner>
      </S.MainContainer>

      <ClickOutHandler onClickOut={() => setToggleMobileWorkSpace(false)}>
        <S.MobileNav active={toggleMobileWorkSpace}>
          <S.MobileWebappNav>
            {auth.isAuthenticated().partnerType != 'INFLUENCER' ? (
              <a>
                <S.MobileNavItem
                  active={location.pathname.includes('cockpit')}
                  onClick={() => navigate('/cockpit')}
                >
                  <S.MobileNotificationDot />
                  <S.MobileNavIcon>
                    <svg version="1.1" viewBox="0 0 32 32">
                      <path d="M13.2 27.5h-6.8v-10.8h-4.1l13.7-12.2 13.7 12.2h-4.1v10.8h-6.8v-8.1h-5.5l-0.1 8.1z"></path>
                    </svg>
                  </S.MobileNavIcon>
                </S.MobileNavItem>
              </a>
            ) : (
              <a>
                <S.MobileNavItem
                  active={location.pathname.includes('skorr-user-media-kit')}
                  onClick={() =>
                    navigate(
                      `/skorr-user-media-kit/${auth.isAuthenticated().userId}`,
                    )
                  }
                >
                  <S.MobileNotificationDot />
                  <S.MobileNavIcon>
                    <svg version="1.1" viewBox="0 0 32 32">
                      <path d="M13.2 27.5h-6.8v-10.8h-4.1l13.7-12.2 13.7 12.2h-4.1v10.8h-6.8v-8.1h-5.5l-0.1 8.1z"></path>
                    </svg>
                  </S.MobileNavIcon>
                </S.MobileNavItem>
              </a>
            )}

            {auth.isAuthenticated().partnerType == 'INFLUENCER' && (
              <a>
                <S.MobileNavItem
                  active={location.pathname.includes('partner/business-edit/')}
                  onClick={() =>
                    navigate(
                      `/partner/business-edit/${
                        auth.isAuthenticated().businessProfileId
                      }`,
                    )
                  }
                >
                  <S.MobileNotificationDot />
                  <S.MobileNavIcon>
                    <svg version="1.1" viewBox="0 0 32 32">
                      <path d="M14.665 10.711c0 2.195-1.759 3.967-3.954 3.967s-3.967-1.772-3.967-3.967 1.772-3.967 3.967-3.967 3.954 1.772 3.954 3.967zM25.243 10.711c0 2.195-1.759 3.967-3.954 3.967s-3.967-1.772-3.967-3.967c0-2.195 1.772-3.967 3.967-3.967s3.954 1.772 3.954 3.967zM10.711 17.322c-3.081 0-9.256 1.547-9.256 4.628v3.306h18.512v-3.306c0-3.081-6.175-4.628-9.256-4.628zM20.007 17.388c0.463-0.040 0.899-0.066 1.283-0.066 3.081 0 9.256 1.547 9.256 4.628v3.306h-7.934v-3.306c0-1.957-1.071-3.451-2.605-4.562z" />
                    </svg>
                  </S.MobileNavIcon>
                </S.MobileNavItem>
              </a>
            )}

            <a>
              <S.MobileNavItem
                active={location.pathname.includes('discover')}
                onClick={() => navigate('/discover')}
              >
                <S.MobileNotificationDot />
                <S.MobileNavIcon>
                  <svg version="1.1" viewBox="0 0 32 32">
                    <path d="M14.665 10.711c0 2.195-1.759 3.967-3.954 3.967s-3.967-1.772-3.967-3.967 1.772-3.967 3.967-3.967 3.954 1.772 3.954 3.967zM25.243 10.711c0 2.195-1.759 3.967-3.954 3.967s-3.967-1.772-3.967-3.967c0-2.195 1.772-3.967 3.967-3.967s3.954 1.772 3.954 3.967zM10.711 17.322c-3.081 0-9.256 1.547-9.256 4.628v3.306h18.512v-3.306c0-3.081-6.175-4.628-9.256-4.628zM20.007 17.388c0.463-0.040 0.899-0.066 1.283-0.066 3.081 0 9.256 1.547 9.256 4.628v3.306h-7.934v-3.306c0-1.957-1.071-3.451-2.605-4.562z" />
                  </svg>
                </S.MobileNavIcon>
              </S.MobileNavItem>
            </a>

            <a
              onClick={(e: React.MouseEvent<HTMLAnchorElement>) => {
                e.stopPropagation();
                setToggleMobileWorkSpace(!toggleMobileWorkSpace);
              }}
            >
              <S.SubmenuWorkspace
                active={toggleMobileWorkSpace}
                selected={
                  location.pathname.includes('partner') ||
                  location.pathname.includes('influencer') ||
                  location.pathname.includes('viewlists')
                }
              >
                <S.MobileNotificationDot />
                <S.MobileNavIcon>
                  <svg version="1.1" viewBox="0 0 32 32">
                    <path d="M7.911 4.259h3.369c2.017 0 3.652 1.635 3.652 3.652v3.369c0 2.017-1.635 3.652-3.652 3.652h-3.37c-2.017 0-3.652-1.635-3.652-3.652v-3.37c0-2.017 1.635-3.652 3.652-3.652zM7.911 17.067h3.369c2.017 0 3.652 1.635 3.652 3.652v3.369c0 2.017-1.635 3.652-3.652 3.652h-3.37c-2.017 0-3.652-1.635-3.652-3.652v-3.369c0-2.017 1.635-3.652 3.652-3.652zM20.719 4.259h3.369c2.017 0 3.652 1.635 3.652 3.652v3.369c0 2.017-1.635 3.652-3.652 3.652h-3.369c-2.017 0-3.652-1.635-3.652-3.652v-3.37c0-2.017 1.635-3.652 3.652-3.652zM20.719 17.067h3.369c2.017 0 3.652 1.635 3.652 3.652v3.369c0 2.017-1.635 3.652-3.652 3.652h-3.369c-2.017 0-3.652-1.635-3.652-3.652v-3.369c0-2.017 1.635-3.652 3.652-3.652z" />
                  </svg>
                </S.MobileNavIcon>
              </S.SubmenuWorkspace>
            </a>

            <a>
              <S.MobileNavItem
                active={location.pathname.includes('benchmark')}
                onClick={() => navigate('/view-benchmark-list')}
              >
                <S.MobileNotificationDot />
                <S.MobileNavIcon>
                  <svg version="1.1" viewBox="0 0 32 32">
                    <path d="M28.655 16c0-6.545-4.945-11.927-11.345-12.655v3.927c4.218 0.582 7.564 4.364 7.564 8.727 0 1.164-0.291 2.182-0.582 3.2l3.345 1.891c0.727-1.455 1.018-3.2 1.018-5.091zM22.836 21.527c-1.6 2.036-4.073 3.345-6.836 3.345v0c-4.945 0-8.873-3.927-8.873-8.873 0-4.509 3.345-8.145 7.564-8.727v-3.927c-6.4 0.727-11.345 6.109-11.345 12.655 0 6.982 5.673 12.655 12.655 12.655 4.218 0 7.855-2.036 10.182-5.236l-3.345-1.891z" />
                  </svg>
                </S.MobileNavIcon>
              </S.MobileNavItem>
            </a>

            <a>
              <S.MobileNavItem
                active={location.pathname.includes('campaign')}
                onClick={() => navigate('/view-campaigns-list')}
              >
                <S.MobileNotificationDot />
                <S.MobileNavIcon>
                  <svg version="1.1" viewBox="0 0 32 32">
                    <path d="M15.709 26.036c0 0.436-0.145 1.018-0.436 1.309-0.291 0.436-0.873 0.582-1.309 0.582h-3.491c-0.873 0-1.6-0.727-1.745-1.455l-0.727-4.655h2.909l4.509 1.891 0.291 2.327zM27.2 4.364c-0.291-0.145-0.582-0.291-1.018-0.291-0.291 0-0.436 0-0.727 0.145l-14.109 5.673h-3.782c-1.891 0-3.491 1.6-3.491 3.491v3.2c0 1.891 1.455 3.2 3.491 3.2h3.782l4.945 2.036 9.164 3.636c0.145 0.145 0.436 0.145 0.727 0.145s0.727-0.145 1.018-0.291c0.436-0.291 0.727-0.873 0.727-1.455v-18.036c0-0.582-0.291-1.164-0.727-1.455z" />
                  </svg>
                </S.MobileNavIcon>
              </S.MobileNavItem>
            </a>

            <a>
              <S.MobileNavItem
                active={location.pathname.includes('mentions')}
                onClick={() => navigate('/mentions')}
              >
                <S.MobileNotificationDot />
                <S.MobileNavIcon>
                  <svg version="1.1" viewBox="0 0 32 32">
                    <path d="M16.718 3.732c0.424 0.061 0.851 0.106 1.272 0.184 2.971 0.549 5.359 2.070 7.238 4.404 1.7 2.112 2.659 4.539 2.943 7.232 0.058 0.546 0.086 1.098 0.089 1.648 0.011 1.948 0.004 3.896 0.004 5.844-0 0.573-0.187 0.76-0.76 0.761-0.463 0.001-0.926 0.002-1.389 0.003-0.015 0-0.031 0.008-0.081 0.022 0 0.083 0.001 0.175-0 0.268-0.005 0.686 0.021 1.375-0.021 2.059-0.075 1.217-0.987 2.070-2.213 2.103-0.535 0.014-1.070 0.008-1.605 0.001-0.372-0.005-0.575-0.197-0.618-0.562-0.006-0.055-0.005-0.112-0.005-0.167-0-3.226-0-6.451 0.001-9.676 0-0.111 0.010-0.224 0.035-0.332 0.057-0.248 0.264-0.409 0.574-0.411 0.614-0.003 1.232-0.021 1.842 0.034 0.961 0.087 1.587 0.633 1.9 1.543 0.019 0.056 0.041 0.112 0.067 0.184 0.022-0.036 0.037-0.048 0.037-0.061 0.124-2.747-0.177-5.405-1.644-7.817-1.332-2.191-3.142-3.815-5.617-4.59-2.873-0.899-5.58-0.464-8.046 1.24-2.439 1.686-3.856 4.067-4.495 6.938-0.31 1.394-0.263 2.808-0.256 4.22 0 0.014 0.011 0.029 0.014 0.035 0.136-0.265 0.245-0.555 0.418-0.8 0.459-0.648 1.119-0.922 1.898-0.93 0.463-0.005 0.926-0.002 1.389-0.001 0.531 0.001 0.738 0.206 0.738 0.738 0.001 2.802 0.001 5.605 0.001 8.407 0 0.431 0.002 0.862-0.001 1.293-0.003 0.506-0.21 0.714-0.711 0.715-0.463 0.001-0.926 0.001-1.389 0-1.398-0.004-2.344-0.937-2.361-2.337-0.008-0.615-0.001-1.23-0.002-1.844 0-0.086 0-0.172 0-0.277-0.514 0-1-0.009-1.486 0.003-0.345 0.009-0.605-0.101-0.745-0.432 0-2.348 0-4.695 0-7.043 0.030-0.267 0.061-0.535 0.091-0.802 0.297-2.674 1.244-5.087 2.93-7.19 1.881-2.345 4.274-3.873 7.254-4.425 0.421-0.078 0.848-0.124 1.273-0.184 0.479 0 0.958 0 1.437 0z" />
                  </svg>
                </S.MobileNavIcon>
              </S.MobileNavItem>
            </a>

            {auth.isAuthenticated().partnerType != 'INFLUENCER' && (
              <a>
                <S.MobileNavItem
                  active={location.pathname.includes('alert')}
                  onClick={() => navigate('/view-alerts-list')}
                >
                  <S.MobileNotificationDot />
                  <S.MobileNavIcon>
                    <svg version="1.1" viewBox="0 0 32 32">
                      <path d="M16 26.8c-5.2 0-9.3-4.2-9.3-9.3s4.2-9.3 9.3-9.3c5.1 0 9.3 4.2 9.3 9.3s-4.1 9.3-9.3 9.3zM16 5.4c-6.6 0-12 5.4-12 12s5.4 12 12 12c6.6 0 12-5.4 12-12s-5.4-12-12-12zM16.7 10.8h-2v8l6.3 3.8 1-1.6-5.3-3.2v-7zM10.5 4.6l-1.7-2-6.2 5.1 1.7 2 6.2-5.1zM29.4 7.7l-6.1-5.2-1.7 2 6.1 5.2 1.7-2z" />
                    </svg>
                  </S.MobileNavIcon>
                </S.MobileNavItem>
              </a>
            )}
          </S.MobileWebappNav>

          <S.WorkspaceSubmenu>
            {auth.isAuthenticated().partnerType == 'AGENCY' && (
              <>
                <S.SubmenuItem
                  onClick={() => {
                    navigate('/influencer/myambassador', {
                      state: { type: 'BY_MYAGENCY' },
                    });
                    setToggleMobileWorkSpace(false);
                  }}
                >
                  {props.t('menu-navbar.subWorkspace.myTalents')}
                  <S.MobileNavArrowContainer>
                    <svg version="1.1" viewBox="0 0 32 32">
                      <path d="M8.656 10.072l7.344 7.328 7.344-7.328 2.256 2.256-9.6 9.6-9.6-9.6 2.256-2.256z" />
                    </svg>
                  </S.MobileNavArrowContainer>
                </S.SubmenuItem>

                <S.SubmenuItem
                  onClick={() => {
                    navigate('/influencer/myTalentInvitations', {
                      state: { type: 'BY_MYAGENCY' },
                    });
                    setToggleMobileWorkSpace(false);
                  }}
                >
                  {props.t('menu-navbar.subWorkspace.myTalentsInvite')}
                  <S.MobileNavArrowContainer>
                    <svg version="1.1" viewBox="0 0 32 32">
                      <path d="M8.656 10.072l7.344 7.328 7.344-7.328 2.256 2.256-9.6 9.6-9.6-9.6 2.256-2.256z" />
                    </svg>
                  </S.MobileNavArrowContainer>
                </S.SubmenuItem>
              </>
            )}

            <S.SubmenuItem
              onClick={() => {
                navigate('/influencer/otherambassador', {
                  state: { type: 'BY_OTHERAGENCIES' },
                });
                setToggleMobileWorkSpace(false);
              }}
            >
              {props.t('menu-navbar.subWorkspace.agencyTalents')}
              <S.MobileNavArrowContainer>
                <svg version="1.1" viewBox="0 0 32 32">
                  <path d="M8.656 10.072l7.344 7.328 7.344-7.328 2.256 2.256-9.6 9.6-9.6-9.6 2.256-2.256z" />
                </svg>
              </S.MobileNavArrowContainer>
            </S.SubmenuItem>

            <S.SubmenuItem
              onClick={() => {
                navigate('/influencer/skorr');
                setToggleMobileWorkSpace(false);
              }}
            >
              {props.t('menu-navbar.subWorkspace.skorrInfluencers')}
              <S.MobileNavArrowContainer>
                <svg version="1.1" viewBox="0 0 32 32">
                  <path d="M8.656 10.072l7.344 7.328 7.344-7.328 2.256 2.256-9.6 9.6-9.6-9.6 2.256-2.256z" />
                </svg>
              </S.MobileNavArrowContainer>
            </S.SubmenuItem>

            <S.SubmenuItem
              onClick={() => {
                navigate('/viewlists');
                setToggleMobileWorkSpace(false);
              }}
            >
              {props.t('menu-navbar.subWorkspace.lists')}
              <S.MobileNavArrowContainer>
                <svg version="1.1" viewBox="0 0 32 32">
                  <path d="M8.656 10.072l7.344 7.328 7.344-7.328 2.256 2.256-9.6 9.6-9.6-9.6 2.256-2.256z" />
                </svg>
              </S.MobileNavArrowContainer>
            </S.SubmenuItem>

            {auth.isAuthenticated().viewer == false &&
              auth.isAuthenticated().partnerType != 'INFLUENCER' &&
              tier != 'TIER1' && (
                <S.SubmenuItem
                  onClick={() => {
                    navigate('/viewcampaigns/invitations');
                    setToggleMobileWorkSpace(false);
                  }}
                >
                  {props.t('menu-navbar.subWorkspace.invitations')}
                  <S.MobileNavArrowContainer>
                    <svg version="1.1" viewBox="0 0 32 32">
                      <path d="M8.656 10.072l7.344 7.328 7.344-7.328 2.256 2.256-9.6 9.6-9.6-9.6 2.256-2.256z" />
                    </svg>
                  </S.MobileNavArrowContainer>
                </S.SubmenuItem>
              )}
          </S.WorkspaceSubmenu>
        </S.MobileNav>
      </ClickOutHandler>

      <GenericModal
        show={showMediaKitSelectionModal}
        title={props.t('menu-navbar.myAnalytics')}
        body={getSocialAccountsMediaKitsSelectionComponent()}
        modalWidth={'80%'}
        primaryButtonText={props.t('generic.close')}
        primaryButtonAction={() => setShowMediaKitSelectionModal(false)}
      />

      <NotifyCustom
        location={'top'}
        show={isLoadingActivateBrandProfile}
        themeStyles={(themeGlobal as any).signin['regular-notify']}
        topInitialOffset={'5rem'}
      >
        <NotifyInnerContainer>
          <InputSpinnerIcon
            containerWidth={'2rem'}
            containerHeight={'2rem'}
            containerMarginRight={'1rem'}
          />
          {loadingActionNotificationMessage}
        </NotifyInnerContainer>
      </NotifyCustom>

      <NotifyCustom
        location={'top'}
        show={showSuccessfulActionNotification}
        themeStyles={(themeGlobal as any).signin['success-notify']}
        topInitialOffset={'5rem'}
      >
        <NotifyInnerContainer>
          <CheckMarkIcon
            containerWidth={'2rem'}
            containerHeight={'2rem'}
            containerMarginRight={'1rem'}
            useCase={'success-notify'}
          />
          {successfulActionNotificationMessage}
        </NotifyInnerContainer>
      </NotifyCustom>

      <NotifyCustom
        location={'top'}
        show={showUnsuccessfulActionNotification}
        themeStyles={(themeGlobal as any).signin['error-notify']}
        topInitialOffset={'5rem'}
      >
        <NotifyInnerContainer>
          <CrossIcon
            containerWidth={'2rem'}
            containerHeight={'2rem'}
            containerMarginRight={'1rem'}
          />
          {unsuccessfulActionNotificationMessage}
        </NotifyInnerContainer>
      </NotifyCustom>
    </>
  );
};

export default withTranslation()(MenuNavbarV2);
